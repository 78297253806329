import { styled, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		border: '1px solid',
		borderColor: theme.palette.primary.dark,
		
		'&:first-of-type': {
			borderRadius: theme.shape.borderRadius,
		},

		'&:not(:first-of-type)': {
			marginLeft: theme.spacing(1),
			border: '1px solid',
			borderRadius: theme.shape.borderRadius,
		},

		'&.Mui-selected': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,

			'&:hover': {
				backgroundColor: theme.palette.primary.dark
			}
		}
	},
}))

export interface SpacedToggleButtonGroupProps extends ToggleButtonGroupProps {
	fixedWidth?: number;
}

export const SpacedToggleButtonGroup: React.FC<SpacedToggleButtonGroupProps> = ({
	fixedWidth,
	sx = [],
	...props
}) => (
	<StyledToggleButtonGroup
		{...props}
		sx={[
			fixedWidth === undefined ? {} : {
				'& .MuiToggleButtonGroup-grouped': {
					width: fixedWidth
				}
			},
			...Array.isArray(sx) ? sx : [sx]
		]}
	/>
)