import { useEffect } from 'react';

/** Executes a handler when the given key is pressed. */
export const useKeyPressListener = (key: string, handler: () => void) => {
	useEffect(() => {
		const eventListener = (e: KeyboardEvent) => {
			if (e.key === key) {
				handler();
			}
		}

		document.addEventListener('keydown', eventListener)
		return () => document.removeEventListener('keydown', eventListener);
	}, [
		key,
		handler
	])
}