import {
  requiredBoolean,
  requiredNumber,
  requiredNumberArray,
  requiredStringArray,
  requiredUuidArray,
} from "app/schema";
import { useMemo } from "react";
import * as yup from "yup";

export const useCampaignFormSchema = () => {
  return useMemo(
    () =>
      yup.object({
        id: yup.string().nullable(),
        name: yup
          .string()
          .required("Please enter a campaign name")
          .max(128, "Please choose a shorter name")
          .default(""),
        title: yup
          .string()
          .required("Please enter a campaign title")
          .default(""),
        description: yup
          .string()
          .required("Please enter a campaign name")
          .max(128, "Please choose a shorter name")
          .default(""),
        priority: requiredNumber.default(100),
        linkUrl: yup.string().nullable(),
        imageUrl: yup.string().nullable(),
        isActive: requiredBoolean.default(false),
        isPushNotifications: requiredBoolean.default(false),
        isInboxMessages: requiredBoolean.default(false),
        credentialTypes: requiredNumberArray.default([]),
        degrees: requiredStringArray.default([]),
        experiences: requiredStringArray.default([]),
        primaryPracticeSettings: requiredNumberArray.default([]),
        triggers: requiredNumberArray.default([]),
        states: requiredStringArray.default([]),
        specialtyCategories: requiredNumberArray.default([]),
        certificationIds: requiredUuidArray.default([]),
        specialtyIds: requiredStringArray.default([]),
        subSpecialtyIds: requiredStringArray.default([]),
        files: yup.mixed(),
      }),
    []
  );
};

export interface CampaignFormFields
  extends yup.Asserts<ReturnType<typeof useCampaignFormSchema>> {}
