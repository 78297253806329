import { Stack } from "@mui/material";
import { SpecialtyMultiSelect } from "./SpecialtyMultiSelect";
import { UseFormReturn, useWatch } from "react-hook-form";
import { SubSpecialtyMultiSelect } from "./SubSpecialtyMultiSelect";
import { useSpecialties } from "../specialty.api";
import { CampaignFormFields } from "pages/campaign/CampaignForm";
import { useEffect, useMemo } from "react";

export interface SpecialtySelectContainerProps {
  form: UseFormReturn<CampaignFormFields, any>;
}

export const SpecialtySelectContainer: React.FC<
  SpecialtySelectContainerProps
> = ({ form }) => {
  const { data: specialities } = useSpecialties();
  const selectedSpecialtyIds = useWatch({ name: "specialtyIds" });
  const subSpecialtyIds = useWatch({ name: "subSpecialtyIds" });

  const subSpecialities = useMemo(
    () =>
      specialities
        ?.filter((x) => selectedSpecialtyIds.includes(x.id))
        .flatMap((x) => x.subSpecialties ?? []) ?? [],
    [specialities, selectedSpecialtyIds]
  );

  // Only keep the subspecialy ids that within the selected specialty ids
  useEffect(() => {
    const ids = subSpecialities.map((x) => x.id);
    const eligbleSubspecialtyIds = (subSpecialtyIds as string[]).filter((x) =>
      ids.includes(x)
    );

    const isEqual =
      subSpecialtyIds.length === eligbleSubspecialtyIds.length &&
      (subSpecialtyIds as string[]).every(
        (value, index) => value === eligbleSubspecialtyIds[index]
      );

    if (!isEqual) {
      form.setValue("subSpecialtyIds", eligbleSubspecialtyIds);
    }
  }, [subSpecialities, subSpecialtyIds, form]);

  return (
    <Stack direction="row" spacing={2}>
      <SpecialtyMultiSelect control={form.control} name="specialtyIds" />
      <SubSpecialtyMultiSelect
        control={form.control}
        name="subSpecialtyIds"
        subSpecialities={subSpecialities}
      />
    </Stack>
  );
};
