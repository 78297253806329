import { CampaignFormFields } from "./campaignFormSchema";
import { Campaign } from "features/campaign";

export const mapFromCampaign = (campaign: Campaign): CampaignFormFields => ({
  id: campaign.id,
  name: campaign.name,
  title: campaign.title,
  imageUrl: campaign.imageUrl,
  description: campaign.description,
  priority: campaign.priority,
  linkUrl: campaign.linkUrl,
  isActive: campaign.isActive,
  isPushNotifications: campaign.pushNotificationTarget != null,
  isInboxMessages: campaign.inboxTarget != null,
  credentialTypes: campaign.credentialTypes ?? [],
  degrees: campaign.degrees ?? [],
  experiences: campaign.experiences ?? [],
  primaryPracticeSettings: campaign.primaryPracticeSettings ?? [],
  triggers: campaign.triggers ?? [],
  states: campaign.states ?? [],
  specialtyCategories: campaign.specialtyCategories ?? [],
  certificationIds: campaign.certifications?.map((x) => x.id) ?? [],
  specialtyIds: campaign.specialties?.map((x) => x.id) ?? [],
  subSpecialtyIds: campaign.subSpecialties?.map((x) => x.id) ?? [],
});
