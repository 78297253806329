import { Stack } from "@mui/material";
import { SplashVideo } from "./SplashVideo";
import ValuePropPage from "./SplashValueProp";
import { SplashFooter } from "./SplashFooter";
import { SplashHeader } from "./SplashHeader";

export const SplashNew: React.FC = () => {
  return (
    <Stack>
      <SplashHeader />
      <SplashVideo />
      <ValuePropPage />
      <SplashFooter />
    </Stack>
  );
};
