import { ApiResponse, appApi } from "app/api";
import { dataTransformSchema, requiredString } from "app/schema";

import * as yup from "yup";

export const exportUrlRequestSchema = yup.object({
  exportId: requiredString,
  password: requiredString,
});

export const exportUrlSchema = yup.object({
  url: requiredString,
});

export interface GetExportUrlRequest
  extends yup.Asserts<typeof exportUrlRequestSchema> {}
export interface ExportUrl extends yup.Asserts<typeof exportUrlSchema> {}

export interface CampaignUploadImageRequest {
  id: string;
  formData: FormData;
}

const exportsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getUrl: builder.mutation<ApiResponse<ExportUrl>, GetExportUrlRequest>({
      query: (body) => ({
        url: `exports/${body.exportId}/urls`,
        method: "POST",
        body: exportUrlRequestSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(exportUrlSchema),
        },
      }),
    }),
  }),
});

export const {
  getUrl: { useMutation: useGetExportUrl },
} = exportsApi.endpoints;
