import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { FieldValues, Path, useController } from "react-hook-form";
import { FormControlProps } from "./FormControlProps";

export interface FormMultiSelectProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends Omit<
    FormControlProps<SelectProps, TFieldValues, TFieldName>,
    "select"
  > {}

export function FormMultiSelect<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...selectProps
}: FormMultiSelectProps<TFieldValues, TFieldName>) {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const { onChange, ...formFieldProps } = field;

  if (!Array.isArray(field.value)) {
    throw new Error(
      "The FormMultiSelectProps component expects an array as the value."
    );
  }

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl>
      <InputLabel shrink={selectProps.displayEmpty}>{label}</InputLabel>
      <Select
        input={<OutlinedInput label={label} notched />}
        multiple
        {...selectProps}
        {...formFieldProps}
        onChange={handleChange}
      />
    </FormControl>
  );
}
