import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from "@mui/material";
import { FieldValues, Path, useController } from "react-hook-form";
import { FormControlProps } from "./FormControlProps";

export interface FormSwitchProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends FormControlProps<SwitchProps, TFieldValues, TFieldName> {
  label?: React.ReactNode;
  FormControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
}

export function FormSwitch<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label = "",
  FormControlLabelProps,
  ...switchProps
}: FormSwitchProps<TFieldValues, TFieldName>) {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const { value, ...formFieldProps } = field;

  if (value !== undefined && typeof value !== "boolean") {
    throw new Error("The FormSwitch component expects a boolean as the value.");
  }

  return (
    <FormControlLabel
      label={label}
      {...FormControlLabelProps}
      control={
        <Switch {...switchProps} checked={value as boolean | undefined} />
      }
      {...formFieldProps}
    />
  );
}
