import { Box, Paper, Stack, Typography } from "@mui/material";
import { ErrorBoundary } from "features/errorHandling";
import React from "react";

const PageTitle: React.FC<{ title: string | React.ReactNode }> = ({
  title,
}) => (
  <Box
    sx={{
      pb: 2,

      "& .MuiTypography-root": {
        fontSize: 24,
        fontWeight: "light",
      },
    }}
  >
    {typeof title !== "string" ? title : <Typography>{title}</Typography>}
  </Box>
);

const DefaultPageComponent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Paper
    elevation={4}
    sx={{
      minWidth: 600,
      minHeight: 450,
      flexGrow: 1,
      borderRadius: 3,
      overflow: "hidden",
    }}
  >
    {children}
  </Paper>
);

export interface PageLayoutProps {
  title?: string | React.ReactNode;
  component?: React.ElementType;
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  component,
  children,
}) => {
  const PageComponent = component ?? DefaultPageComponent;

  return (
    <Stack
      sx={{
        minHeight: "100%",
        pt: 2,
        pr: 3,
        pb: 3,
        pl: 5,
      }}
    >
      {title && <PageTitle title={title} />}
      <PageComponent>
        <ErrorBoundary>{children}</ErrorBoundary>
      </PageComponent>
    </Stack>
  );
};
