import { Box, Chip, Divider, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FullScreenButton } from "./FullScreenButton";
import { ProfileMenu } from "./ProfileMenu";
import { AppLogo } from "app/AppLogo";

const Header: React.FC<{
  navigationWidth: number;
  children?: React.ReactNode;
}> = ({ navigationWidth, children }) => (
  <Box
    component="header"
    sx={{
      width: "100%",
      bgcolor: "background.paper",
      boxShadow: `${navigationWidth + 4}px 2px 8px 0 #0000000f`,

      "& .MuiTypography-root": {
        fontSize: 21,
      },
    }}
  >
    <Stack
      alignItems="center"
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 2 }}
      sx={{
        px: 1,
        py: { xs: 1, sm: 1 },
      }}
    >
      {children}
    </Stack>
  </Box>
);

const ToolbarDivider = () => (
  <Divider orientation="vertical" variant="middle" flexItem />
);

const Logo = () => (
  <Link
    component={RouterLink}
    to="/dashboard"
    underline="none"
    sx={{ color: "inherit" }}
  >
    <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 2 }}>
      <AppLogo width={34} height={34} />
      <Typography sx={{ color: "#13436A" }}>Medentials</Typography>
    </Stack>
  </Link>
);

export const SiteToolbar: React.FC<{ navigationWidth: number }> = ({
  navigationWidth,
}) => {
  return (
    <Header navigationWidth={navigationWidth}>
      <Logo />
      <ToolbarDivider />
      <Box flex={1} />
      <ProfileMenu />
      {/* <FullScreenButton /> */}
    </Header>
  );
};
