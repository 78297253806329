import {
  ExportUrlForm,
  ExportUrlFormFields,
} from "./ExportUrlForm/ExportUrlForm";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { useGetExportUrl } from "features/exports/exports.api";
import { AppLogo } from "app/AppLogo";
import { useParams } from "react-router-dom";
import { useNotify } from "features/notifications";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "app/LoadingIndicator";

const Logo = () => (
  <Box>
    <Box
      sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
    >
      <AppLogo width={60} height={60} />
    </Box>
    <Typography fontSize={36} color="text.primary">
      Medentials
    </Typography>
  </Box>
);

export const ExportUrl: React.FC = () => {
  const [getExportUrl] = useGetExportUrl();
  const { exportId } = useParams();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  // Try once without password
  useEffect(() => {
    if (!exportId) {
      return;
    }

    const fetch = async () => {
      try {
        setIsLoading(true);
        const result = await getExportUrl({
          exportId,
          password: "",
        }).unwrap();

        window.location.href = result.data.url;
      } catch (err) {
        setIsLoading(false);
      }
    };

    fetch();
  }, [exportId, getExportUrl]);

  const handleSubmit = async (fields: ExportUrlFormFields) => {
    try {
      const result = await getExportUrl({
        exportId: fields.exportId,
        password: fields.password,
      }).unwrap();

      window.location.href = result.data.url;
    } catch (err) {
      notify({
        title: `Incorrect password. Please try again.`,
        persist: false,
        severity: "error",
      });
    }
  };

  if (!exportId) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(/loginBackground.svg)`,
      }}
    >
      <Paper
        elevation={15}
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          paddingBottom: 9,
          px: 15,
          borderRadius: 2,
          pt: 4,
        }}
      >
        <Box>
          <Stack spacing={2} alignItems="center" sx={{ mt: 2 }}>
            <Logo />
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <Typography fontSize={16} fontWeight="light">
                  Please provide password
                </Typography>
                <ExportUrlForm exportId={exportId} onSubmit={handleSubmit} />
              </>
            )}
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};
