import { useTokenRevoke } from "features/authentication";
import { useCallback } from "react";

export type LogoutFunc = () => Promise<void>;

export const useLogout = (): LogoutFunc => {
  const [revokeToken] = useTokenRevoke();

  return useCallback(async () => {
    await revokeToken();
    window.location.assign("/");
  }, [revokeToken]);
};
