import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, Stack } from "@mui/material";
import { exportUrlRequestSchema } from "features/exports/exports.api";
import { FormTextField, useFormSubmittingOverlay } from "features/form";
import { useLogger } from "features/logging";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface ExportUrlFormProps {
  exportId: string;
  onSubmit: (fields: ExportUrlFormFields) => Promise<void>;
}

export interface ExportUrlFormFields
  extends yup.Asserts<typeof exportUrlRequestSchema> {}

export const ExportUrlForm: React.FC<ExportUrlFormProps> = ({
  exportId,
  onSubmit,
}) => {
  const log = useLogger("ExportUrlForm");
  const form = useForm<ExportUrlFormFields>({
    resolver: yupResolver(exportUrlRequestSchema),
    defaultValues: {
      exportId,
    },
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useFormSubmittingOverlay(form.formState);

  const handleSubmit = form.handleSubmit(onSubmit, (errors) => {
    console.log(errors);
    log.debug(
      "Unable to submit the form because there are validation errors.",
      { errors }
    );
  });

  return (
    <Stack
      spacing={3}
      component="form"
      onSubmit={handleSubmit}
      sx={{
        alignItems: "center",
        "& .MuiTextField-root": { width: "45ch" },
      }}
    >
      <FormTextField
        control={form.control}
        name="password"
        label="Password"
        type="password"
        autoComplete="current-password"
        sx={{ width: 300 }}
      />
      <Input type="hidden" {...form.register("exportId")} />
      <Button variant="contained" color="primary" size="medium" type="submit">
        Submit
      </Button>
    </Stack>
  );
};
