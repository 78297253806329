import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import {
  FormSwitch,
  FormTextField,
  useFormSubmittingOverlay,
} from "features/form";
import { useLogger } from "features/logging";
import { ConfirmationDialog } from "features/modals";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  CampaignFormFields,
  useCampaignFormSchema,
} from "./campaignFormSchema";
import { CertificationMultiSelect } from "features/certification";
import {
  ExperienceMultiSelect,
  PrimaryPracticeSettingMultiSelect,
  StateMultiSelect,
  SpecialtySelectContainer,
  CampaignTriggerMultiSelect,
  SpecialtyCategoryMultiSelect,
  useListMatchedUsers,
  User,
  UserGrid,
} from "features/user";
import { CampaignPreview } from "../CampaignPreview";
import { plural } from "features/language";

const DeleteConfirmationMessage: React.FC<{ campaignName: string }> = ({
  campaignName,
}) => {
  return (
    <Typography>
      Are you sure you would like to delete the campaign schedule
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        {campaignName}
      </Typography>
      ?
    </Typography>
  );
};

export interface CampaignFormProps {
  onSubmit: (form: CampaignFormFields) => Promise<void>;
  onCreateAds?: () => Promise<void>;
  onDelete?: () => void;
  onCancel: () => void;
  defaultValues?: CampaignFormFields;
}

export const CampaignForm: React.FC<CampaignFormProps> = ({
  onSubmit,
  onCreateAds,
  onDelete,
  onCancel,
  defaultValues,
}) => {
  const log = useLogger("CampaignForm");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [matchedUsers, setMatchedUsers] = useState<User[]>([]);
  const [matchedUsersCount, setMatchedUsersCount] = useState(0);

  const schema = useCampaignFormSchema();
  const form = useForm<CampaignFormFields>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues ?? schema.cast({}),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const [title, description, files, isActive] = form.watch([
    "title",
    "description",
    "files",
    "isActive",
  ]);
  const [
    triggers,
    specialtyCategories,
    specialtyIds,
    subSpecialtyIds,
    experiences,
    primaryPracticeSettings,
    states,
    certificationIds,
  ] = form.watch([
    "triggers",
    "specialtyCategories",
    "specialtyIds",
    "subSpecialtyIds",
    "experiences",
    "primaryPracticeSettings",
    "states",
    "certificationIds",
  ]);

  const [listMatchedUsers] = useListMatchedUsers();

  const pictureUrl = useMemo(() => {
    if (!files || files.length === 0) {
      return;
    }

    return URL.createObjectURL(files[0]);
  }, [files]);

  useFormSubmittingOverlay(form.formState);

  useEffect(() => {
    const refresh = async () => {
      const data = await listMatchedUsers({
        triggers,
        specialtyCategories,
        specialtyIds,
        subSpecialtyIds,
        experiences,
        primaryPracticeSettings,
        states,
        certificationIds,
        excludeTriggers: true,
      }).unwrap();
      setMatchedUsersCount(data.data.total);
      setMatchedUsers(data.data.users);
    };
    refresh();
  }, [
    listMatchedUsers,
    triggers,
    specialtyCategories,
    specialtyIds,
    subSpecialtyIds,
    experiences,
    primaryPracticeSettings,
    states,
    certificationIds,
  ]);

  const handleSubmit = form.handleSubmit(onSubmit, (errors) => {
    console.log(errors);
    log.debug(
      "Unable to submit the form because there are validation errors.",
      { errors }
    );
  });

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    setOpenDeleteDialog(false);
    onDelete && onDelete();
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Stack
      component="form"
      sx={{ height: "100%", overflowY: "auto" }}
      onSubmit={handleSubmit}
    >
      <ConfirmationDialog
        open={openDeleteDialog}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        text={
          defaultValues?.name && (
            <DeleteConfirmationMessage campaignName={defaultValues.name} />
          )
        }
      />
      <FormProvider {...form}>
        <Box>
          <Stack spacing={3} sx={{ px: 4, pt: 2 }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormTextField
                control={form.control}
                name="name"
                label="Name"
                sx={{ width: 300 }}
              />
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{
                  pb: 2,
                }}
              >
                <Typography>Is Active</Typography>
                <FormSwitch name="isActive" control={form.control} />
              </Stack>
            
            </Stack>
            <FormTextField
              control={form.control}
              name="title"
              label="Title"
              fullWidth
            />
            <FormTextField
              control={form.control}
              name="description"
              label="Description"
              fullWidth
            />
            <FormTextField
              control={form.control}
              name="linkUrl"
              label="Link"
              sx={{ width: 300 }}
            />
            <FormTextField
              control={form.control}
              name="priority"
              type="number"
              label="Priority"
            />
            <Stack spacing={2} direction="row">
              <Typography variant="h5">Image Upload</Typography>
              <FormControl>
                <Input type="file" {...form.register("files")} />
                <FormHelperText
                  error={form.formState.errors.files !== undefined}
                >
                  {form.formState.errors.files?.message?.toString()}
                </FormHelperText>
              </FormControl>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h5">Preview</Typography>
              <CampaignPreview
                image={pictureUrl ?? defaultValues?.imageUrl}
                title={title}
                description={description}
              />
            </Stack>

            <CampaignTriggerMultiSelect
              name="triggers"
              control={form.control}
            />
            <Stack direction="row" spacing={4}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography>Push Notifications</Typography>
                <FormSwitch name="isPushNotifications" control={form.control} />
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography>Inbox Messages</Typography>
                <FormSwitch name="isInboxMessages" control={form.control} />
              </Stack>
            </Stack>
            <Typography variant="h5">Criteria</Typography>
            <SpecialtyCategoryMultiSelect
              name="specialtyCategories"
              control={form.control}
            />
            <SpecialtySelectContainer form={form} />
            <Stack direction="row" spacing={2}>
              <ExperienceMultiSelect
                control={form.control}
                name="experiences"
              />
              <PrimaryPracticeSettingMultiSelect
                control={form.control}
                name="primaryPracticeSettings"
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <StateMultiSelect control={form.control} name="states" />
              {/* <DegreeMultiSelect control={form.control} name="degrees" /> */}
              {/* <CredentialTypeMultiSelect
                control={form.control}
                name="credentialTypes"
              /> */}
              <CertificationMultiSelect
                control={form.control}
                name="certificationIds"
              />
            </Stack>

            <Stack sx={{ height: 400, pb: "20px" }}>
              <Typography variant="h5">
                Matched Users ({matchedUsers.length})
              </Typography>
              <UserGrid users={matchedUsers} loading={false} />
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction="row-reverse"
          sx={{
            background: "#fff",
            px: 4,
            py: 2,
            position: "fixed",
            bottom: 0,
            right: 0,
            justifyContent: "space-between",
            "& .MuiButton-root": {
              width: 135,
            },
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{`${matchedUsersCount} ${plural(
              matchedUsersCount,
              "matched user"
            )}`}</Typography>
            {defaultValues?.id && (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                sx={{ color: "#FFF" }}
                disabled={!isActive}
                onClick={onCreateAds}
              >
                Execute
              </Button>
            )}
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
            >
              {defaultValues?.id ? "Update" : "Create"}
            </Button>
          </Stack>
          {onDelete && (
            <Button
              variant="contained"
              color="error"
              size="medium"
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          )}
        </Stack>
      </FormProvider>
    </Stack>
  );
};
