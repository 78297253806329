import { ApiResponse, appApi } from "app/api";
import {
  nullableString,
  nullableUuid,
  dataTransformSchema,
  requiredBoolean,
  requiredNumber,
  requiredString,
  requiredUuid,
  stringArray,
  numberArray,
  requiredUuidArray,
  nullableNumber,
} from "app/schema";
import * as yup from "yup";

export const userResourceSchema = yup.object({
  id: requiredUuid,
  email: yup.string().email().required(),
  isEmailVerified: requiredBoolean,
  phoneNumber: nullableString,
  fullName: nullableString,
  address1: nullableString,
  address2: nullableString,
  city: nullableString,
  state: nullableString,
  zip: nullableString,
  degree: nullableString,
  primaryPracticeSetting: nullableNumber,
  specialtyId: nullableUuid,
  subSpecialtyId: nullableUuid,
  isOnboarded: requiredBoolean,
  isDeviceAvailable: requiredBoolean,
  experience: nullableString,
  title: nullableString,
  createdAt: requiredString,
  updatedAt: requiredString,
});

const userMatchCampaignSchema = yup.object({
  credentialTypes: numberArray,
  degrees: stringArray,
  experiences: stringArray,
  primaryPracticeSettings: numberArray,
  triggers: numberArray,
  states: stringArray,
  specialtyCategories: numberArray,
  certificationIds: requiredUuidArray,
  specialtyIds: stringArray,
  subSpecialtyIds: stringArray,
  excludeTriggers: yup.boolean(),
});

const userListSchema = yup.object({
  users: yup.array().of(userResourceSchema).required(),
  total: requiredNumber,
});

export interface User extends yup.Asserts<typeof userResourceSchema> {}
export interface UserList extends yup.Asserts<typeof userListSchema> {}

export interface UserMatchCampaignRequest
  extends yup.Asserts<typeof userMatchCampaignSchema> {}

const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listUsers: builder.query<UserList, void>({
      query: () => "users",
      extraOptions: {
        schema: dataTransformSchema(userListSchema),
      },
    }),

    listMatchedUsers: builder.mutation<
      ApiResponse<UserList>,
      UserMatchCampaignRequest
    >({
      query: (body) => ({
        url: `users/campaigns`,
        method: "POST",
        body: userMatchCampaignSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(userListSchema),
        },
      }),
    }),
  }),
});

export const {
  listUsers: { useQuery: useUsers },
  listMatchedUsers: { useMutation: useListMatchedUsers },
} = userApi.endpoints;
