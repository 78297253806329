import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Campaign } from "./campaign.api";
import {
  campaignTriggers,
  credentialTypes,
  degrees,
  primaryPracticeSettings,
} from "app/data";

const DetailCell: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => (
  <Stack>
    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
      {title}
    </Typography>
    <Typography variant="subtitle2">{description}</Typography>
  </Stack>
);

export interface CampaignDetailDialogProps {
  open: boolean;
  campaign?: Campaign;
  onDismiss: () => void;
}

export const CampaignDetailDialog: React.FC<CampaignDetailDialogProps> = ({
  open,
  campaign,
  onDismiss,
}) => {
  if (!campaign) {
    return <></>;
  }

  const certifications =
    !campaign.certifications || campaign.certifications.length === 0
      ? "All"
      : campaign.certifications.map((x) => campaign.name).join(", ");
  const specialties =
    !campaign.specialties || campaign.specialties.length === 0
      ? "All"
      : campaign.specialties.map((x) => campaign.name).join(", ");
  const subSpecialties =
    !campaign.subSpecialties || campaign.subSpecialties.length === 0
      ? "All"
      : campaign.subSpecialties.map((x) => campaign.name).join(", ");
  const campaignCredentialTypes =
    !campaign.credentialTypes || campaign.credentialTypes.length === 0
      ? "All"
      : campaign.credentialTypes.map((x) => credentialTypes[x]).join(", ");
  const campaignDegrees =
    !campaign.degrees || campaign.degrees.length === 0
      ? "All"
      : campaign.degrees.map((x) => degrees[parseInt(x)]).join(", ");
  const campaignPrimaryPracticeSettings =
    !campaign.primaryPracticeSettings ||
    campaign.primaryPracticeSettings.length === 0
      ? "All"
      : campaign.primaryPracticeSettings
          .map((x) => primaryPracticeSettings[x])
          .join(", ");
  const campaignTriggersString =
    !campaign.triggers || campaign.triggers.length === 0
      ? "All"
      : campaign.triggers.map((x) => campaignTriggers[x]).join(", ");
  const states =
    !campaign.states || campaign.states.length === 0
      ? "All"
      : campaign.states.join(", ");

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          m: 0,
          display: "flex",
          justifyContent: "space-between",
          color: "grey.700",
        }}
      >
        {campaign.name}
        {open ? (
          <IconButton
            aria-label="close"
            onClick={onDismiss}
            sx={{ color: "grey.600" }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <Box
            id="confirm-dialog-description"
            sx={{
              color: "grey.700",
            }}
          >
            <Stack spacing={2} sx={{ mb: 2 }}>
              <DetailCell
                title="Triggers"
                description={campaignTriggersString}
              />
              <DetailCell title="Certification" description={certifications} />
              <DetailCell title="Specialty" description={specialties} />
              <DetailCell title="Sub-Specialty" description={subSpecialties} />
              <DetailCell
                title="Credential Types"
                description={campaignCredentialTypes}
              />
              <DetailCell title="Degrees" description={campaignDegrees} />
              <DetailCell
                title="Primary Practice Settings"
                description={campaignPrimaryPracticeSettings}
              />
              <DetailCell title="States" description={states} />
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
