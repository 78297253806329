import { KeyboardArrowDown, Logout, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "app/store.helper";
import { useLogout } from "features/connections/useLogout";
import React, { useRef, useState } from "react";

interface MenuItemProps {
  onSelected: () => void;
}

const LogoutMenuItem: React.FC<MenuItemProps> = ({ onSelected }) => {
  const logout = useLogout();

  const handleClick = () => {
    logout();
    onSelected();
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <Logout sx={{ color: "primary.contrastText" }} />
      </ListItemIcon>
      <ListItemText>Logout</ListItemText>
    </MenuItem>
  );
};

export const ProfileMenu = () => {
  const session = useSelector((x) => x.connection.session);
  const buttonElement = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  if (!session) {
    return null;
  }

  const handleMenuItemSelected = () => setOpen(false);

  return (
    <Box>
      <Button
        ref={buttonElement}
        endIcon={<KeyboardArrowDown />}
        disableRipple
        sx={{
          fontSize: 16,
          fontWeight: "light",
          color: "text.primary",
          textTransform: "none",

          "& .MuiSvgIcon-root": {
            transform: `rotate(${open ? -180 : 0}deg)`,
            transition: (theme) =>
              `transform ${theme.transitions.duration.short}ms`,
          },
        }}
        onClick={() => setOpen((isOpen) => !isOpen)}
      >
        {session.userId}
      </Button>
      <Popover
        open={open}
        anchorEl={buttonElement.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setOpen(false)}
      >
        <Stack
          spacing={2}
          sx={{
            pt: 2,
            pb: 0.5,
            width: 300,
            bgcolor: "primary.dark",
            color: "primary.contrastText",

            "& > :not(.menu)": {
              px: 2,
            },
          }}
        >
          <Stack direction="row" spacing={1}>
            <Person sx={{ color: "primary.contrastText" }} />
            <Typography>{session.userId}</Typography>
          </Stack>

          <Box className="menu">
            <Divider sx={{ bgcolor: "primary.contrastText", opacity: 0.5 }} />
            <MenuList>
              <LogoutMenuItem onSelected={handleMenuItemSelected} />
            </MenuList>
          </Box>
        </Stack>
      </Popover>
    </Box>
  );
};
