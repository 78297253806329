import { FormDrawer } from "features/modals";
import { useNotify } from "features/notifications";
import React from "react";
import {
  useCreateCampaign,
  useUploadCampaignImage,
} from "features/campaign/campaign.api";
import { CampaignFormFields } from "./CampaignForm";
import { CampaignForm } from "./CampaignForm/CampaignForm";
import { useCampaignFormToCampaignMapper } from "./CampaignForm/useCampaignFormToCampaignMapper";

export interface CreateCampaignDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const CreateCampaignDrawer: React.FC<CreateCampaignDrawerProps> = ({
  open,
  onClose,
}) => {
  const notify = useNotify();
  const [createCampaign] = useCreateCampaign();
  const [uploadCampaignImage] = useUploadCampaignImage();
  const [mapToRequest] = useCampaignFormToCampaignMapper();

  const handleCreateCampaign = async (values: CampaignFormFields) => {
    try {
      const request = mapToRequest(values);
      const campaign = (await createCampaign(request).unwrap()).data;

      const { files } = values;
      if (files && files.length > 0) {
        const [file] = files;
        const formData = new FormData();
        formData.append("file", file);
        await uploadCampaignImage({ id: campaign.id, formData });
      }

      onClose();
    } catch {
      notify({
        title: `Failed to create the campaign '${values.name}'.`,
        persist: false,
        severity: "error",
      });
    }
  };

  // const defaultValues = {
  //   subSpecialtyIds: ["207KI0005X", "207KA0200X"],
  //   specialtyIds: ["171100000X", "207K00000X", "229N00000X", "207L00000X"],
  //   certificationIds: [
  //     "983536ce-f126-4642-bcdd-99dc6fe05a2f",
  //     "afde2d1f-4ea9-42c2-b3fe-a4ea99b7f566",
  //   ],
  //   specialtyCategories: [],
  //   states: ["AK", "AL"],
  //   primaryPracticeSettings: [0, 1],
  //   triggers: [],
  //   experiences: ["In Training", "0-4 years"],
  //   degrees: ["0", "1"],
  //   credentialTypes: [0, 1],
  //   isActive: false,
  //   isPushNotifications: false,
  //   isInboxMessages: false,
  //   description: "Test 2",
  //   name: "Test",
  //   title: "Test Title",
  // };
  return (
    <FormDrawer open={open} title="New Campaign" onClose={onClose}>
      <CampaignForm
        onSubmit={handleCreateCampaign}
        onCancel={onClose}
      />
    </FormDrawer>
  );
};
