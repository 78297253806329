import { Stack, Box, Typography } from "@mui/material";
import { useRef, useEffect, useState } from "react";

export const SplashVideo = () => {
  const [playVideo, setPlayVideo] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (playVideo && videoRef?.current) {
      videoRef.current.play();
    }
  }, [playVideo]);

  return (
    <Stack
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: { md: "100vh" },
        color: "#fff",
        background: "#06685A",
        p: { xs: 3, md: 6 },
        fontFamily: '"sofia-pro", "Roboto", sans-serif',
      }}
      alignItems="center"
      justifyContent={{ md: "center" }}
    >
      <Typography
        sx={{
          fontSize: { xs: "48px", md: "48px" },
          lineHeight: { xs: "67px", md: "32px" },
          fontWeight: 800,
          mb: 5,
        }}
      >
        Take the nuisance out of credentialing with Medentials
      </Typography>
      <Box
        width={{
          xs: "100%",
          md: 920,
        }}
        sx={{
          background: "#252A31",
          position: "relative",
          borderColor: "#252A31",
          borderWidth: { xs: "12px", md: "32px" },
          borderStyle: "solid",
          borderRadius: { xs: 12, md: 20 },
          p: { xs: 2 },
        }}
      >
        <video width="100%" ref={videoRef} controls={playVideo}>
          <source
            src="https://prod-medentials-content.s3.us-west-2.amazonaws.com/app/Medentials+_+HD-2.mp4"
            type="video/mp4"
          />
          Sorry, your browser doesn't support videos.
        </video>
        {!playVideo && (
          <img
            style={{
              cursor: "pointer",
              background: "#fff",
              borderRadius: "50px",
              padding: 20,
              width: "100px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={() => setPlayVideo(true)}
            src="play.svg"
            alt="play"
          />
        )}
      </Box>
    </Stack>
  );
};
