import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "app/store.helper";
import React, { useEffect, useMemo } from "react";
import { Notification } from "./Notification";
import {
  clearActiveToast,
  closeActiveToast,
  displayNextToast,
} from "./notificationsSlice";
import { useNotifications } from "./useNotifications";

const empty = "";

export const ToastContainer: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useSelector((x) => x.notifications.toast);
  const { notifications, getActions, remove } = useNotifications();

  useEffect(() => {
    if (toast.pending.length && !toast.active) {
      dispatch(displayNextToast());
    } else if (toast.pending.length && toast.active && toast.open) {
      dispatch(closeActiveToast());
    }
  }, [toast, dispatch]);

  const active = useMemo(() => {
    const notification = notifications.find((x) => x.id === toast.active);
    return notification ? { ...notification } : undefined;
  }, [notifications, toast.active]);

  const handleClose = () => dispatch(closeActiveToast());
  const handleExited = () => {
    if (active) {
      dispatch(clearActiveToast());

      if (!active.persist) {
        remove(active.id);
      }
    }
  };

  const actionsEntry = active ? getActions(active.id) : undefined;
  const actions =
    actionsEntry?.toast === undefined
      ? actionsEntry?.main
      : actionsEntry?.toast;

  let title: string;
  if (typeof active?.toast === "object") {
    title = active.toast.title ?? active.title;
  } else if (active) {
    title = active.title;
  } else {
    title = empty;
  }

  return (
    <Snackbar
      open={toast.open}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionProps={{ onExited: handleExited }}
      sx={(theme) => ({
        bottom: { xs: theme.spacing(4) },
      })}
    >
      <Notification
        title={title}
        onClose={handleClose}
        severity={active?.severity}
        actions={actions}
        sx={{
          border: "1px solid",
          borderColor: `${active?.severity}.main`,
          borderRadius: 3,
        }}
      />
    </Snackbar>
  );
};
