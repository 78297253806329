import * as yup from "yup";
import { appApi } from "app/api";

export const timeZoneSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
  ianaName: yup.string().required(),
});

export interface TimeZone extends yup.Asserts<typeof timeZoneSchema> {}

export const timeZoneApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeZones: builder.query<TimeZone[], void>({
      query: () => "api/timezones",
      extraOptions: {
        schema: yup.array().of(timeZoneSchema.required()).required(),
      },
    }),
  }),
});

export const {
  getTimeZones: { useQuery: useTimeZonesQuery },
} = timeZoneApi.endpoints;
