import { IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useState } from "react";
import { ValueProp, ValueProps } from ".";

const ValuePropView: React.FC<ValueProp> = ({ index, title, description }) => {
  return (
    <Stack direction="column" alignItems="center" spacing={2} sx={{ p: 2 }}>
      <img src={`value_prop_${index}_mobile.png`} alt="" width={242} />
      <Stack spacing={3} sx={{ pb: 2 }}>
        <Typography
          sx={{
            color: "#000",
            fontSize: "71px",
            lineHeight: "82px",
            fontFamily: '"sofia-pro", "Roboto", sans-serif',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontSize: "18px",
            lineHeight: "36px",
            fontFamily: '"sofia-pro", "Roboto", sans-serif',
          }}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ValuePropMobile: React.FC<ValueProps> = ({ items }) => {
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((i) => {
      let index = i + 1;

      if (index >= items.length) {
        index = 0;
      }
      return index;
    });
  };

  const handlePrevious = () => {
    setIndex((i) => {
      let index = i - 1;

      if (index < 0) {
        index = items.length - 1;
      }
      return index;
    });
  };

  return (
    <Stack
      direction="column"
      spacing={5}
      sx={{ background: "#FFF", mt: 7, pb: 15 }}
      alignItems="center"
      position="relative"
    >
      <ValuePropView {...items[index]} />
      <Stack
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        width="100%"
        height={250}
        sx={{ position: "absolute", top: 40, p: 2 }}
      >
        <IconButton
          onClick={handlePrevious}
          disableRipple
          sx={{
            color: "#007F6D",
            p: 2,
            backgroundColor: "#ECF8F7",
            border: "1px solid #afafaf",
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disableRipple
          sx={{
            color: "#007F6D",
            backgroundColor: "#ECF8F7",
            p: 2,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
