import { TextField, TextFieldProps } from "@mui/material";
import { FieldValues, Path, useController } from "react-hook-form";
import { FormControlProps } from "./FormControlProps";
import { FormErrorMessage } from "./FormErrorMessage";

export interface FormTextFieldProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends FormControlProps<TextFieldProps, TFieldValues, TFieldName> {}

export function FormTextField<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  ...fieldProps
}: FormTextFieldProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <TextField
      {...fieldProps}
      {...field}
      error={!!fieldState.error}
      helperText={<FormErrorMessage error={fieldState.error} />}
    />
  );
}
