import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useLoadingOverlay } from './useLoadingOverlay';

export const GlobalLoadingOverlay: React.FC = () => {
	const { open } = useLoadingOverlay();

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	}

	return (
		<Backdrop open={open} onClick={handleClick} sx={{
			zIndex: Number.MAX_SAFE_INTEGER
		}}>
			<CircularProgress />
		</Backdrop>
	)
}