import { useDispatch, useSelector } from "app/store.helper";
import { useCallback } from "react";
import { actionRepository, NotificationActionEntry } from "./actionRepository";
import {
  markNotificationsRead,
  NotificationsState,
  removeNotification,
} from "./notificationsSlice";

export interface NotificationsHookResult {
  notifications: NotificationsState["notifications"];
  getActions: (id: string) => NotificationActionEntry | undefined;
  remove: (id: string) => void;
  markAllRead: () => void;
}

const getActions = (id: string) => actionRepository.get(id);

export const useNotifications = (): NotificationsHookResult => {
  const dispatch = useDispatch();
  const notifications = useSelector((x) => x.notifications.notifications);

  const remove = useCallback(
    (id: string) => {
      dispatch(removeNotification({ id }));
      actionRepository.delete(id);
    },
    [dispatch]
  );

  const markAllRead = useCallback(() => {
    dispatch(markNotificationsRead());
  }, [dispatch]);

  return {
    notifications,
    getActions,
    remove,
    markAllRead,
  };
};
