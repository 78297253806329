import { Stack, Typography } from "@mui/material";
import { ShareIcons } from "./ShareIcons";

export const SplashFooter = () => {
  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      alignItems="center"
      justifyContent={{ xs: "end", md: "center" }}
      sx={{
        bgcolor: "#027F6D",
        height: { xs: 400, md: 317 },
        color: "#fff",
      }}
      spacing={{ xs: 2, md: 10 }}
      pb={{ xs: 7 }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography>
          <a
            href="https://blog.medentials.com"
            target="_blank"
            rel="noreferrer"
            style={{
              display: 'block',
              color: "#fff",
              textDecoration: "none",
              fontSize: "22px",
              fontFamily: '"sofia-pro", "Roboto", sans-serif',
            }}
          >
           Blog
          </a>
        </Typography>
        <Typography sx={{ fontSize: "22px" }}>•</Typography>
        <Typography>
          <a
            href="/privacy"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontSize: "22px",
              fontFamily: '"sofia-pro", "Roboto", sans-serif',
            }}
          >
            Privacy Policy
          </a>
        </Typography>
        <Typography sx={{ fontSize: "22px" }}>•</Typography>
        <Typography>
          <a
            href="mailto:admin@medentials.com"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#fff",
              textDecoration: "none",
              fontSize: "22px",
              fontFamily: '"sofia-pro", "Roboto", sans-serif',
            }}
          >
            Contact
          </a>
        </Typography>
      </Stack>
      <ShareIcons />
      <Stack sx={{ position: "relative" }}>
        <img
          src="footer_logo.png"
          alt=""
          width={290}
          style={{
            position: "absolute",
            top: "-260px",
            right: 0,
            fontFamily: '"sofia-pro", "Roboto", sans-serif',
          }}
        />
        <Typography sx={{ fontSize: "52px", fontWeight: 900 }}>
          Medentials
        </Typography>
      </Stack>
    </Stack>
  );
};
