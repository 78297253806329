import { ApiResponse, appApi } from "app/api";
import {
  dataTransformSchema,
  requiredBoolean,
  requiredString,
  requiredUuid,
  requiredUuidArray,
  numberArray,
  stringArray,
  requiredNumber,
  nullableString,
} from "app/schema";
import { certificationResourceSchema } from "features/certification";
import { userResourceSchema } from "features/user";
import {
  specialtyResourceSchema,
  subSpecialtyResourceSchema,
} from "features/user/specialty.api";
import * as yup from "yup";

const campaignRequestSchema = yup.object({
  name: requiredString,
  title: requiredString,
  description: requiredString,
  linkUrl: yup.string().nullable(),
  isActive: requiredBoolean,
  credentialTypes: numberArray,
  degrees: stringArray,
  experiences: stringArray,
  primaryPracticeSettings: numberArray,
  triggers: numberArray,
  states: stringArray,
  specialtyCategories: numberArray,
  certificationIds: requiredUuidArray,
  specialtyIds: stringArray,
  subSpecialtyIds: stringArray,
});

const campaignSchema = yup.object({
  name: requiredString,
  title: requiredString,
  userId: requiredUuid,
  description: requiredString,
  priority: requiredNumber,
  linkUrl: nullableString,
  imageUrl: yup.string().nullable(),
  isActive: requiredBoolean,
  pushNotificationTarget: nullableString,
  inboxTarget: nullableString,
  createdAt: requiredString,
  updatedAt: requiredString,
  credentialTypes: numberArray,
  degrees: stringArray,
  experiences: stringArray,
  primaryPracticeSettings: numberArray,
  triggers: numberArray,
  states: stringArray,
  specialtyCategories: numberArray,
  certifications: yup.array().of(certificationResourceSchema),
  specialties: yup.array().of(specialtyResourceSchema),
  subSpecialties: yup.array().of(subSpecialtyResourceSchema),
});

const campaignResourceSchema = campaignSchema.shape({
  id: requiredUuid,
  matchedUsers: yup.array().of(userResourceSchema),
  matchedUsersCount: requiredNumber,
});

const updateCampaignRequestSchema = campaignRequestSchema.shape({
  id: requiredUuid,
});

export interface CreateCampaignRequest
  extends yup.Asserts<typeof campaignRequestSchema> {}
export interface UpdateCampaignRequest
  extends yup.Asserts<typeof updateCampaignRequestSchema> {}
export interface Campaign extends yup.Asserts<typeof campaignResourceSchema> {}

export interface CampaignUploadImageRequest {
  id: string;
  formData: FormData;
}

const campaignApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listCampaigns: builder.query<Campaign[], void>({
      query: () => "campaigns",
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(campaignResourceSchema)),
      },
      providesTags: ["Campaigns"],
    }),

    createCampaign: builder.mutation<
      ApiResponse<Campaign>,
      CreateCampaignRequest
    >({
      query: (body) => ({
        url: "campaigns",
        method: "POST",
        body: campaignRequestSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(campaignResourceSchema),
        },
      }),
      invalidatesTags: ["Campaigns"],
    }),

    updateCampaign: builder.mutation<
      ApiResponse<Campaign>,
      UpdateCampaignRequest
    >({
      query: (body) => ({
        url: `campaigns/${body.id}`,
        method: "PATCH",
        body: campaignRequestSchema.cast(body),
        extraOptions: {
          schema: dataTransformSchema(campaignResourceSchema),
        },
      }),
      invalidatesTags: ["Campaigns"],
    }),

    uploadCampaignImage: builder.mutation<
      ApiResponse<Campaign>,
      CampaignUploadImageRequest
    >({
      query: (body) => ({
        url: `campaigns/${body.id}/images`,
        method: "POST",
        body: body.formData,
        extraOptions: {
          schema: dataTransformSchema(campaignResourceSchema),
        },
      }),
      invalidatesTags: ["Campaigns"],
    }),

    deleteCampaign: builder.mutation<ApiResponse<Campaign>, string>({
      query: (campaignId) => ({
        url: `campaigns/${campaignId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Campaigns"],
    }),

    createAds: builder.mutation<void, string>({
      query: (campaignId) => ({
        url: `campaigns/${campaignId}/ads`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  listCampaigns: { useQuery: useCampaigns },
  createCampaign: { useMutation: useCreateCampaign },
  updateCampaign: { useMutation: useUpdateCampaign },
  uploadCampaignImage: { useMutation: useUploadCampaignImage },
  deleteCampaign: { useMutation: useDeleteCampaign },
  createAds: { useMutation: useCreateAds },
} = campaignApi.endpoints;
