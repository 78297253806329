import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { User } from "./user.api";
import { primaryPracticeSettings, degrees } from "app/data";
import { parsePhoneNumber } from "libphonenumber-js";
import { Specialty } from "./specialty.api";

const DetailCell: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => (
  <Stack>
    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
      {title}
    </Typography>
    <Typography variant="subtitle2">{description}</Typography>
  </Stack>
);

export interface UserDetailDialogProps {
  open: boolean;
  user?: User;
  specialities?: Specialty[];
  onDismiss: () => void;
}

export const UserDetailDialog: React.FC<UserDetailDialogProps> = ({
  open,
  user,
  specialities,
  onDismiss,
}) => {
  const specialtyNameById = useMemo(() => {
    const map = new Map<string, string>();
    specialities?.forEach((x) => map.set(x.id, x.name));
    return map;
  }, [specialities]);

  if (!user) {
    return <></>;
  }

  const primaryPracticeSetting = user.primaryPracticeSetting
    ? primaryPracticeSettings[user.primaryPracticeSetting]
    : null;

  const address2 = [user.address2, user.city, user.state, user.zip]
    .filter((x) => x)
    .join(", ");

  const isDeviceAvailable = user.isDeviceAvailable ? "Yes" : "No";

  const phoneNumber = user.phoneNumber
    ? parsePhoneNumber(user.phoneNumber, "US").formatInternational()
    : "N/A";

  const degree = user.degree ? degrees[parseInt(user.degree)] : null;

  const specialty = user.specialtyId
    ? specialtyNameById.get(user.specialtyId)
    : null;

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          m: 0,
          display: "flex",
          justifyContent: "space-between",
          color: "grey.700",
        }}
      >
        {user.fullName ?? "User Detail"}
        {open ? (
          <IconButton
            aria-label="close"
            onClick={onDismiss}
            sx={{ color: "grey.600" }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <Box
            id="confirm-dialog-description"
            sx={{
              color: "grey.700",
            }}
          >
            <Stack spacing={2} sx={{ mb: 2 }}>
              <DetailCell title="ID" description={user.id} />
              {user.fullName && (
                <DetailCell title="Name" description={user.fullName} />
              )}
              <DetailCell title="Email" description={user.email} />
              {phoneNumber && (
                <DetailCell title="Phone Number" description={phoneNumber} />
              )}
              {user.address1 && (
                <DetailCell
                  title="Address Line 1"
                  description={user.address1}
                />
              )}
              {address2 && (
                <DetailCell title="Address Line 2" description={address2} />
              )}
              {degree && <DetailCell title="Degree" description={degree} />}
              {primaryPracticeSetting && (
                <DetailCell
                  title="Primary Practice Setting"
                  description={primaryPracticeSetting}
                />
              )}
              {user.experience && (
                <DetailCell title="Experience" description={user.experience} />
              )}
              {user.title && (
                <DetailCell title="Title" description={user.title} />
              )}
              {specialty && (
                <DetailCell title="Specialty" description={specialty} />
              )}
              {isDeviceAvailable && (
                <DetailCell
                  title="Is Device Available"
                  description={isDeviceAvailable}
                />
              )}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
