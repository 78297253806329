import React from 'react';
import { FieldError } from 'react-hook-form';

export interface FormErrorMessageProps {
	error: FieldError | undefined;
}

export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ error }) => {
	if (!error) {
		return null;
	}

	if (error.message) return (<>
		{error.message}
	</>)

	if (Array.isArray(error)) return (<>
		{error.map(x => (
			<React.Fragment key={x.message}>
				{x.message}<br />
			</React.Fragment>
		))}
	</>)

	return null;
}