import { CampaignFormFields } from "./campaignFormSchema";
import { CreateCampaignRequest } from "features/campaign/campaign.api";

export enum PushNotificationTarget {
  count,
  duration,
  indefinite,
}

export enum InboxTarget {
  views,
  clicks,
  indefinite,
}

export type CampaignFormToCampaignMapper = (
  scheduleForm: CampaignFormFields
) => CreateCampaignRequest;

export type CampaignFormToCampaignMapperHookResult = [
  CampaignFormToCampaignMapper
];

export const useCampaignFormToCampaignMapper =
  (): CampaignFormToCampaignMapperHookResult => {
    return [
      (x) => {
        return {
          name: x.name,
          title: x.title,
          description: x.description,
          priority: x.priority,
          linkUrl: x.linkUrl,
          isActive: x.isActive,
          pushNotificationTarget: x.isPushNotifications
            ? PushNotificationTarget.indefinite
            : null,
          inboxTarget: x.isInboxMessages ? InboxTarget.indefinite : null,
          credentialTypes: x.credentialTypes,
          degrees: x.degrees,
          experiences: x.experiences,
          primaryPracticeSettings: x.primaryPracticeSettings,
          triggers: x.triggers,
          states: x.states,
          certificationIds: x.certificationIds,
          specialtyIds: x.specialtyIds,
          subSpecialtyIds: x.subSpecialtyIds,
          specialtyCategories: x.specialtyCategories,
        };
      },
    ];
  };
