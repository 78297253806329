import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { LoadingIndicator } from "app/LoadingIndicator";
import { column } from "features/datagrid";
import React, { useMemo } from "react";
import { Specialty, User } from "features/user";
import { Typography } from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import { primaryPracticeSettings } from "app/data";
import { format } from "date-fns";

const EmptyUser: React.FC = () => {
  return <Typography sx={{ fontSize: 16 }}>No Users</Typography>;
};

export interface UserInfo {
  id: string;
  name: string;
}

const columns: GridColDef[] = [
  column.string({
    width: 160,
    field: "fullName",
    headerName: "Name",
  }),
  column.string({
    width: 400,
    field: "email",
    headerName: "Email",
  }),
  column.string({
    width: 200,
    field: "phoneNumber",
    headerName: "Number",
  }),
  column.string({
    width: 300,
    field: "specialtyName",
    headerName: "Specialty",
  }),
  column.string({
    width: 300,
    field: "primaryPracticeSetting",
    headerName: "Practice Setting",
  }),
  column.dateTime({
    field: "createdAt",
    headerName: "Joined At",
    sortable: true,
    type: "dateTime",
    filterable: true,
  }),
];

export interface UserDetailGridProps {
  users?: User[];
  loading: boolean;
  specialities?: Specialty[];
  selectedRow?: string;
  onRowSelect?: (userId?: string) => void;
}

export const UserDetailGrid: React.FC<UserDetailGridProps> = ({
  users,
  loading,
  specialities,
  selectedRow,
  onRowSelect,
}) => {
  const rows = useMemo(() => {
    if (!users) {
      return [];
    }

    const specialtyNameById = new Map<string, string>();
    specialities?.forEach((x) => specialtyNameById.set(x.id, x.name));

    return users.map((x) => {
      const phoneNumber = x.phoneNumber
        ? parsePhoneNumber(x.phoneNumber, "US").formatInternational()
        : "N/A";
      return {
        id: x.id,
        fullName: x.fullName ?? "N/A",
        email: x.email,
        phoneNumber,
        specialtyName: x.specialtyId
          ? specialtyNameById.get(x.specialtyId)
          : null,
        primaryPracticeSetting: x.primaryPracticeSetting
          ? primaryPracticeSettings[x.primaryPracticeSetting]
          : "N/A",
        createdAt: new Date(x.createdAt).getTime() / 1000,
      };
    });
  }, [users, specialities]);

  const handleRowSelect = (selection: GridSelectionModel) => {
    const [rowId] = selection;
    if (!rowId) {
      onRowSelect?.(undefined);
      return;
    }

    onRowSelect?.(rowId as string);
  };

  if (rows.length === 0) {
    return <EmptyUser />;
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LoadingIndicator,
      }}
      getRowHeight={() => 50}
      loading={loading}
      selectionModel={selectedRow ? [selectedRow] : []}
      onSelectionModelChange={handleRowSelect}
      hideFooter
      sx={{
        border: 0,
        m: 3,
        "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
          {
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
      }}
    />
  );
};
