import { appApi } from "app/api";
import {
  dataTransformSchema,
  requiredNumber,
  requiredString,
  requiredUuid,
} from "app/schema";
import * as yup from "yup";

export const certificationResourceSchema = yup.object({
  id: requiredUuid,
  name: requiredString,
  category: requiredNumber,
});

export interface Certification
  extends yup.Asserts<typeof certificationResourceSchema> {}

const certificationApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listCertifications: builder.query<Certification[], void>({
      query: () => "certifications",
      extraOptions: {
        schema: dataTransformSchema(
          yup.array().of(certificationResourceSchema)
        ),
      },
    }),
  }),
});

export const {
  listCertifications: { useQuery: useCertifications },
} = certificationApi.endpoints;
