import {
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  FormControlLabelProps,
} from "@mui/material";
import { FormToggleButtonGroup, FormControlProps } from "features/form";
import React, { useEffect, useMemo, useState } from "react";
import { useWatch, FieldValues, Path, useController } from "react-hook-form";

interface ControlProps {}

interface FormMonthDaySelectProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends FormControlProps<ControlProps, TFieldValues, TFieldName> {
  label?: React.ReactNode;
  FormControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
}

const allDays = Array.from({ length: 29 });

export function FormMonthDaySelect<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
}: FormMonthDaySelectProps<TFieldValues, TFieldName>) {
  const { field } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const { onChange, value, ref, ...formFieldProps } = field;
  const [specificDay, setSpecificDay] = useState("2");
  const monthDays = useWatch({ name, control });

  const usingSpecificDay = useMemo(() => {
    if (monthDays === undefined) {
      return false;
    }
    if (Array.isArray(monthDays)) {
      const arr = monthDays as string[];
      if (arr.length > 0) {
        return arr[0] !== "1" && arr[0] !== "31";
      }
    }
    return false;
  }, [monthDays]);

  const handleSpecificDayChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSpecificDay(e.target.value);
    onChange([e.target.value]);
  };

  useEffect(() => {
    const length = value.length;
    if (length > 1) {
      onChange([value[length - 1]]);
    }
  }, [value, onChange]);

  return (
    <Stack direction="row" spacing={1}>
      <FormToggleButtonGroup
        control={control}
        size="small"
        fixedWidth={120}
        requireSelection
        {...formFieldProps}
      >
        <ToggleButton value="1">First Day</ToggleButton>
        <ToggleButton value="31">Last Day</ToggleButton>
        <ToggleButton value={specificDay}>Specific Day</ToggleButton>
      </FormToggleButtonGroup>
      <TextField
        value={specificDay}
        onChange={handleSpecificDayChange}
        disabled={!usingSpecificDay}
        size="small"
        select
        SelectProps={{
          MenuProps: {
            sx: {
              maxHeight: 400,
            },
          },
        }}
      >
        {allDays.map((_, i) => {
          const value = String(i + 2);
          return (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </TextField>
    </Stack>
  );
}
