import { Stack, Typography } from "@mui/material";
import { ValueProp, ValueProps } from ".";
import React from "react";

const ValuePropView: React.FC<ValueProp> = ({ index, title, description }) => {
  return (
    <Stack
      direction={index % 2 === 0 ? "row" : "row-reverse"}
      alignItems="top"
      spacing={2}
      sx={{ pl: 4, pr: 4, pb: 2 }}
    >
      <img src={`value_prop_${index}.png`} alt="" width={580} />
      <Stack spacing={3} sx={{ maxWidth: "500px" }}>
        <Typography
          sx={{
            color: "#000",
            fontSize: "96px",
            lineHeight: "110px",
            mt: 6,
            fontFamily: '"sofia-pro", "Roboto", sans-serif',
          }}
        >
          {title}
        </Typography>
        <Stack direction="row" alignItems="top" spacing={1}>
          <img
            src={`value_prop_${index}_icon.png`}
            alt=""
            height={24}
            style={{ marginTop: 6 }}
          />
          <Typography
            sx={{
              color: "#000",
              fontSize: "18px",
              lineHeight: "36px",
              fontFamily: '"sofia-pro", "Roboto", sans-serif',
            }}
          >
            {description}
          </Typography>
        </Stack>
        <img
          src={`value_prop_${index}_bg.png`}
          alt=""
          style={{ marginTop: 6, width: 300 }}
        />
      </Stack>
    </Stack>
  );
};

export const ValuePropDesktop: React.FC<ValueProps> = ({ items }) => {
  return (
    <Stack
      direction="column"
      spacing={5}
      sx={{ background: "#FFF", mt: 7 }}
      alignItems="center"
      position="relative"
    >
      <Stack
        width="100%"
        spacing={4}
        direction="column"
        alignItems="center"
        sx={{ pb: 4 }}
      >
        {items.map((item) => (
          <ValuePropView key={item.index} {...item} />
        ))}
      </Stack>
    </Stack>
  );
};
