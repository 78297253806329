import { createTheme, ThemeOptions } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { CheckCircleOutline } from "@mui/icons-material";

import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

// Theming
// https://mui.com/system/basics/#usage
// https://mui.com/customization/theming
// https://mui.com/customization/default-theme

export interface SeverityColor {
  main: string;
  contrastText: string;
}

declare module "@mui/material/styles" {
  interface Palette {
    boringBlue: string;

    severity: {
      verySevere: SeverityColor;
      severe: SeverityColor;
      minor: SeverityColor;
      veryMinor: SeverityColor;
    };
  }

  interface PaletteOptions {
    boringBlue: string;

    severity: {
      verySevere: SeverityColor;
      severe: SeverityColor;
      minor: SeverityColor;
      veryMinor: SeverityColor;
    };
  }

  interface TypographyVariants {
    roboto: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    roboto?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    roboto: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#2196F3",
      main: "#00A58E",
      dark: "#0B416A",
    },

    secondary: {
      light: "#D1D5DB",
      main: "#2F66A9",
    },

    success: {
      light: "#61CEA1",
      main: "#27CE89",
      contrastText: "#FFFFFF",
    },

    info: {
      main: "#1C97F3",
      contrastText: "#FFFFFF",
    },

    warning: {
      main: "#FBC96E",
      contrastText: grey[700],
    },

    error: {
      main: "#F14967",
      light: "#F5785F",
    },

    background: {
      default: "#E7EAEE",
    },

    boringBlue: "#18436A",
  },

  components: {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircleOutline fontSize="inherit" />,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        cell: {
          ":focus": {
            outline: "none",
          },
          ":focus-within": {
            outline: "none",
          },
        },
        columnHeader: {
          ":focus": {
            outline: "none",
          },
          ":focus-within": {
            outline: "none",
          },
        },
      },
    },

    MuiDrawer: {
      defaultProps: {
        anchor: "right",
      },
    },

    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "#0000006",
        },
        iconWrapper: {
          color: "#BDBDBD",
        },
      },
    },
  },

  shape: {
    borderRadius: 5,
  },
} as ThemeOptions);

// Further configure theme with access to the calculated theme.
theme = createTheme(theme, {
  components: {
    MuiSelect: {
      styleOverrides: {
        standard: {
          color: theme.palette.primary.main,
          fontWeight: 600,
          ":focus": {
            backgroundColor: "initial",
          },
        },
      },
    },
  },

  palette: {
    severity: {
      verySevere: {
        main: theme.palette.secondary.main,
        contrastText: theme.palette.common.white,
      },
      severe: {
        main: theme.palette.secondary.light,
        contrastText: theme.palette.common.white,
      },
      minor: {
        main: grey[500],
        contrastText: theme.palette.common.white,
      },
      veryMinor: {
        main: grey[300],
        contrastText: theme.palette.common.white,
      },
    },
  },

  shadows: {
    ...theme.shadows,
    3: "1px 4px 10px -2px #0000002b",
  },
} as ThemeOptions);

export default theme;
