import { Stack, Typography } from "@mui/material";

export const EmptyCampaign: React.FC = () => {
  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
      spacing={1}
    >
      <Typography sx={{ fontSize: 24 }}>No Campaigns</Typography>
      <Typography sx={{ fontSize: 17 }}>
        Click "Create Campaign" to get started
      </Typography>
    </Stack>
  );
};
