import { Info } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { SearchInput } from "features/input";

export enum UserAction {
  Inspect,
}

const getActionIcon = (action: UserAction) => {
  switch (action) {
    case UserAction.Inspect:
      return <Info />;
  }
};

const getActionLabel = (action: UserAction) => {
  switch (action) {
    case UserAction.Inspect:
      return "Summary";
  }
};

export interface UserHeaderProps {
  searchTerm: string;
  setIsSearching: (isSearching: boolean) => void;
  onSearchChange: (searchTerm: string) => void;
  onActionClick: (action: UserAction) => void;
  isEditable: boolean;
}

export const UserHeader: React.FC<UserHeaderProps> = ({
  searchTerm,
  setIsSearching,
  onSearchChange,
  isEditable,
  onActionClick,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        mt: 2,
        pb: 1,
      }}
    >
      {(isEditable ? [UserAction.Inspect] : []).map((action) => (
        <Button
          key={action}
          size="small"
          variant="text"
          sx={{ fontWeight: "bold" }}
          startIcon={getActionIcon(action)}
          onClick={(_) => onActionClick(action)}
        >
          {getActionLabel(action)}
        </Button>
      ))}

      <Box sx={{ flex: 1 }} />
      <SearchInput
        label="Filter by name or email"
        size="small"
        sx={{ width: 400 }}
        value={searchTerm}
        onBlur={() => setIsSearching(false)}
        onChange={(value) => onSearchChange(value)}
        fullWidth
      />
    </Stack>
  );
};
