import {
  Checkbox,
  FormControlLabelProps,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { FormMultiSelect } from "features/form";
import { FieldValues, useWatch, Path } from "react-hook-form";
import { FormControlProps } from "features/form";
import { states } from "app/data";

const renderSelectValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.length === 0
      ? "All States"
      : `${value.length} state${value.length === 1 ? "" : "s"}`;
  }
};
interface ControlProps {}

export interface StateMultiSelectProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends FormControlProps<ControlProps, TFieldValues, TFieldName> {
  label?: React.ReactNode;
  FormControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
}

export function StateMultiSelect<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({ control, name }: StateMultiSelectProps<TFieldValues, TFieldName>) {
  const selectedStates = useWatch({ name, control });

  return (
    <FormMultiSelect
      control={control}
      name={name}
      label="States"
      size="small"
      sx={{ width: 358 }}
      renderValue={renderSelectValue}
      displayEmpty
      MenuProps={{ style: { height: 200 } }}
    >
      {states.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          <Checkbox
            checked={selectedStates.includes(id)}
            sx={{ p: 0, pr: 1 }}
          />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </FormMultiSelect>
  );
}
