import React from 'react';
import moment from 'moment';

export interface DateProps {
	seconds: number;
	includeWeekday?: boolean;
	short?: boolean;
	format?: string;
	local?: boolean;
}

export const Date: React.FC<DateProps> = ({ seconds, includeWeekday, short, format, local }) => {
	const date = local
		? moment.unix(seconds)
		: moment.unix(seconds).utc();

	if (!format) {
		format = short ? 'L' : 'MMMM Do YYYY';

		if (includeWeekday) {
			format = `${short ? 'ddd' : 'dddd,'} ${format}`;
		}
	}

	return (<>
		{date.format(format)}
	</>)
}