import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

export interface FormDrawerProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}

export const FormDrawer: React.FC<FormDrawerProps> = ({
  open,
  title,
  onClose,
  children,
}) => {
  return (
    <Drawer open={open} disableEscapeKeyDown>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "primary.main",
            px: 4,
            py: 2,
            "& .MuiSvgIcon-root, .MuiTypography-root": {
              color: "primary.contrastText",
              fontWeight: "medium",
            },
          }}
        >
          <Typography component="div" variant="roboto" sx={{ fontSize: 24 }}>
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "inherit", fontWeight: "inherit" }} />
          </IconButton>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </Stack>
    </Drawer>
  );
};
