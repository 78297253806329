import {
  Button,
  Popover,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import CircleIcon from "@mui/icons-material/Circle";

export enum ActiveOption {
  Inactive = "Inactive",
  Active = "Active",
}

export const ActiveMenuButton: React.FC<{
  isActive: boolean;
  options: ActiveOption[];
  onOptionSelect: (option: ActiveOption) => void;
}> = ({ isActive, options, onOptionSelect }) => {
  const buttonElement = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      buttonElement.current &&
      buttonElement.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleSelect = (option: ActiveOption) => {
    setOpen(false);
    onOptionSelect(option);
  };

  return (
    <>
      <Button
        ref={buttonElement}
        endIcon={<KeyboardArrowDown />}
        disableRipple
        sx={{
          fontSize: 16,
          fontWeight: "light",
          color: "text.primary",
          textTransform: "none",
        }}
        onClick={() => setOpen((isOpen) => !isOpen)}
      >
        <CircleIcon sx={{ color: isActive ? "green" : "#A30000", width: 16 }} />
        <Typography
          variant="body1"
          sx={{
            ml: 1,
            "&.MuiTypography-root": {
              fontSize: 14,
            },
          }}
        >
          {isActive ? "Active" : "Inactive"}
        </Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={buttonElement.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={() => setOpen(false)}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open}>
              {options.map((option) => (
                <MenuItem key={option} onClick={() => handleSelect(option)}>
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  );
};
