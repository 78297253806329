import React, { useMemo } from 'react';
import moment from 'moment';

export interface DurationProps {
	seconds: number;
}

export const Duration: React.FC<DurationProps> = ({ seconds }) => {
	const parts = useMemo(() => {
		const duration = moment.duration({ seconds });
		const time = {
			days: duration.days(),
			hours: duration.hours(),
			minutes: duration.minutes(),
			seconds: duration.seconds()
		}

		const parts: string[] = [];

		if (time.days) parts.push(`${time.days} ${time.days > 1 ? 'days' : 'day'}`);
		if (time.hours) parts.push(`${time.hours} ${time.hours > 1 ? 'hours' : 'hour'}`);
		if (time.minutes) parts.push(`${time.minutes} ${time.minutes > 1 ? 'minutes' : 'minute'}`);
		if (time.seconds) parts.push(`${time.seconds} ${time.seconds > 1 ? 'seconds' : 'second'}`);

		if (parts.length === 0) {
			parts.push('0 seconds');
		}

		return parts;
	}, [seconds])

	return (<>
		{parts.join(' ')}
	</>)
}