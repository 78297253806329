import {
  DataGrid,
  GridSelectionModel,
  GridRowParams,
  GridColDef,
} from "@mui/x-data-grid";
import { LoadingIndicator } from "app/LoadingIndicator";
import { column } from "features/datagrid";
import React, { useMemo } from "react";
import { EmptyCampaign } from "./EmptyCampaign";
import { Campaign } from "features/campaign";
import { Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";

export interface CampaignInfo {
  id: string;
  name: string;
}

const columns: GridColDef[] = [
  column.boolean({
    width: 100,
    field: "isActive",
    headerName: "Active",
    sortable: false,
    renderCell: (cell) => {
      return (
        <CircleIcon
          sx={{ color: cell.value === "Yes" ? "green" : "#A30000", width: 16 }}
        />
      );
    },
  }),
  column.number({
    field: 'priority',
    headerName: 'Priority',
  }),
  column.string({
    width: 200,
    field: "name",
    headerName: "Name",
  }),
  column.number({
    field: "matchedUsersCount",
    headerName: "Users",
  }),
  column.string({
    width: 140,
    field: "createdAt",
    headerName: "Created",
    renderCell: (cell) => {
      return (
        <Typography variant="subtitle2">
          {moment(cell.value).fromNow()}
        </Typography>
      );
    },
  }),
  column.string({
    width: 140,
    field: "updatedAt",
    headerName: "Updated",
    renderCell: (cell) => {
      return (
        <Typography variant="subtitle2">
          {moment(cell.value).fromNow()}
        </Typography>
      );
    },
  }),
];

export interface CampaignGridProps {
  campaigns?: Campaign[];
  selectedRow?: string;
  onRowSelect?: (campaignId?: string) => void;
  onRowEdit?: (campaignId: string) => void;
  loading: boolean;
}

export const CampaignGrid: React.FC<CampaignGridProps> = ({
  campaigns,
  selectedRow,
  onRowSelect,
  onRowEdit,
  loading,
}) => {
  const rows = useMemo(() => {
    if (!campaigns) {
      return [];
    }

    console.log("campaigns", campaigns);

    return campaigns.map((x) => {
      return {
        id: x.id,
        isActive: x.isActive,
        priority: x.priority,
        createdAt: x.createdAt,
        updatedAt: x.updatedAt,
        matchedUsersCount: x.matchedUsersCount,
        name: x.name,
      };
    });
  }, [campaigns]);

  const handleRowEdit = (row: GridRowParams) => {
    onRowEdit?.(row.id as string);
  };

  const handleRowSelect = (selection: GridSelectionModel) => {
    const [rowId] = selection;
    if (!rowId) {
      onRowSelect?.(undefined);
      return;
    }

    onRowSelect?.(rowId as string);
  };

  if (rows.length === 0) {
    return <EmptyCampaign />;
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LoadingIndicator,
      }}
      getRowHeight={() => 50}
      loading={loading}
      selectionModel={selectedRow ? [selectedRow] : []}
      onSelectionModelChange={handleRowSelect}
      onRowDoubleClick={handleRowEdit}
      hideFooter
      sx={{
        border: 0,
        pt: 1,
        "& .MuiDataGrid-columnHeader:first-of-type": {
          pl: 5,
        },
        "& .MuiDataGrid-columnHeader:last-of-type": {
          pr: 5,
        },
        "& .MuiDataGrid-cell:first-of-type": {
          pl: 5,
        },
        "& .MuiDataGrid-cell:last-of-type": {
          pr: 5,
        },
        "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
          {
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
      }}
    />
  );
};
