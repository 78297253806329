import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { Link, matchPath, PathMatch, useLocation } from "react-router-dom";
import AssessmentIcon from "@mui/icons-material/Assessment";
// import { TableChart } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

export function useRouteMatch(...patterns: readonly string[]) {
  const { pathname } = useLocation();

  let match: PathMatch<string> | null = null;
  patterns.find((pattern) => (match = matchPath(pattern, pathname)));

  return match!;
}

const NavigationSectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <Typography
    sx={{
      fontSize: 14,
      color: "#0D3C61",
      fontWeight: 500,
      pl: 2,
      pt: 2,
      pb: 1,
    }}
  >
    {title}
  </Typography>
);

export const Navigation: React.FC<{ width: number }> = ({ width }) => {
  const match = useRouteMatch(
    "/dashboard",
    "/campaigns",
    "/users",
    "/recipients"
  );
  const currentRoute = match?.pattern?.path ?? "/campaigns";

  return (
    <Stack
      sx={{
        width,
        height: "100%",

        py: 1.5,
        bgcolor: "#f5f5f5",

        "& .MuiTab-root": {
          minHeight: "40px",
          justifyContent: "flex-start",
        },
        "& .Mui-selected": {
          color: "#000000",
          bgcolor: "#ecf5fe",

          "& .MuiSvgIcon-root": {
            color: "#2196F3",
          },
        },
        "& .MuiTabs-indicator": {
          bgcolor: "info.light",
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Tabs
          variant="fullWidth"
          value={currentRoute}
          orientation="vertical"
          textColor="inherit"
          sx={{ height: "100%", pl: 1, pr: 1, pb: 1 }}
          TabIndicatorProps={{ hidden: true }}
        >
          <NavigationSectionTitle title="Management" />
          {/* <Tab
            icon={<TableChart />}
            iconPosition="start"
            label="Dashboard"
            value="/dashboard"
            to="/dashboard"
            component={Link}
            sx={{ pb: 2 }}
          /> */}
          <Tab
            icon={<AssessmentIcon />}
            iconPosition="start"
            label="Campaigns"
            value="/campaigns"
            to="/campaigns"
            component={Link}
            sx={{ pb: 2 }}
          />
          <Tab
            icon={<PeopleAltIcon />}
            iconPosition="start"
            label="Users"
            value="/users"
            to="/users"
            component={Link}
            sx={{ pb: 2 }}
          />
          <Tab
            icon={<AssignmentIndIcon />}
            iconPosition="start"
            label="Recipients"
            value="/recipients"
            to="/recipients"
            component={Link}
            sx={{ pb: 2 }}
          />
        </Tabs>
      </Box>
      <Divider variant="fullWidth" />
    </Stack>
  );
};
