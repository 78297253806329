import { Close, HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { ReactNode } from "react";

export enum ConfirmationType {
  ok,
  yesNo,
}

export interface ConfirmationDialogProps {
  open: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  title?: ReactNode;
  type?: ConfirmationType;
  text: ReactNode | string;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  title = "Confirm",
  type = ConfirmationType.yesNo,
  text,
}) => {
  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          color: "grey.700",
        }}
      >
        {title}
        {open ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: "grey.600" }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <HelpOutline sx={{ color: "grey.700" }} />
          <Box
            id="confirm-dialog-description"
            sx={{
              ml: 2,
              color: "grey.700",
            }}
          >
            {text}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
        {type === ConfirmationType.yesNo && (
          <Stack direction="row" spacing={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleConfirm}
              sx={{ width: "100px" }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "100px" }}
            >
              No
            </Button>
          </Stack>
        )}
        {type === ConfirmationType.ok && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={handleClose}
            sx={{ width: "100px" }}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
