import { Alert, AlertProps, Box, Stack, SxProps, Theme } from '@mui/material';
import React from 'react';

export type NotificationAlertProps = Pick<AlertProps,
	'onClose' |
	'variant' |
	'severity' |
	'sx' |
	'children'
>

export interface NotificationProps {
	title: string;
	onClose?: () => void;
	severity?: AlertProps['severity'];
	actions?: React.ReactNode;
	renderAlert?: (props: NotificationAlertProps) => React.ReactNode;
	sx?: SxProps<Theme>;
}

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(({
	title,
	onClose,
	severity = 'success',
	actions,
	renderAlert,
	sx = []
}, ref) => {
	const alertProps: NotificationAlertProps = {
		variant: 'outlined',
		severity,
		onClose,
		children: title,
		sx: {
			bgcolor: 'transparent',
			border: 0
		}
	}

	return (
		<Box ref={ref} sx={[
			{ bgcolor: 'background.paper' },
			...Array.isArray(sx) ? sx : [sx]
		]}		>
			{renderAlert ? renderAlert(alertProps) : (
				<Alert {...alertProps} />
			)}
			{actions && (
				<Stack
					className="Notification-actions"
					direction="row-reverse"
					spacing={2}
					sx={{ justifyContent: 'center', px: 2, pb: 2 }}
				>
					{actions}
				</Stack>
			)}
		</Box>
	)
})