import { Stack, Typography } from "@mui/material";

export interface CampaignPreviewProps {
  image?: string | null;
  title: string;
  description: string;
}

export const CampaignPreview: React.FC<CampaignPreviewProps> = ({
  image,
  title,
  description,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{ border: "1px solid #c9c9c9", p: 2, borderRadius: 2 }}
    >
      {image && <img src={image} alt="preview" width={52} height={52} />}
      <Stack alignItems="start">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Stack>
  );
};
