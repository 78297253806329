import {
  Checkbox,
  FormControlLabelProps,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { FormMultiSelect } from "features/form";
import React from "react";
import { FieldValues, useWatch, Path } from "react-hook-form";
import { FormControlProps } from "features/form";
import { experiences } from "app/data";

const renderSelectValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.length === 0
      ? "All Experiences"
      : `${value.length} experience${value.length === 1 ? "" : "s"}`;
  }
};
interface ControlProps {}

export interface ExperienceMultiSelectProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends FormControlProps<ControlProps, TFieldValues, TFieldName> {
  label?: React.ReactNode;
  FormControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
}

export function ExperienceMultiSelect<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({ control, name }: ExperienceMultiSelectProps<TFieldValues, TFieldName>) {
  const selectedExperiences = useWatch({ name, control });

  return (
    <FormMultiSelect
      control={control}
      name={name}
      label="Experiences"
      size="small"
      sx={{ width: 358 }}
      renderValue={renderSelectValue}
      displayEmpty
      MenuProps={{ style: { height: 200 } }}
    >
      {experiences.map((experience) => (
        <MenuItem key={experience} value={experience}>
          <Checkbox
            checked={selectedExperiences.includes(experience)}
            sx={{ p: 0, pr: 1 }}
          />
          <ListItemText primary={experience} />
        </MenuItem>
      ))}
    </FormMultiSelect>
  );
}
