import React, { useEffect, useState } from 'react';
import {
	IconButton,
	InputAdornment,
	TextField,
	TextFieldProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchInputProps
	extends Omit<TextFieldProps, 'value' | 'onChange' | 'onKeyDown'> {
	value: string;
	onChange: (value: string) => void;
	debounceMillis?: number;
}

export const SearchInput: React.FC<SearchInputProps> = ({
	value,
	onChange,
	debounceMillis = 400,
	...props
}) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		if (debouncedValue === value) {
			return;
		}

		const debounce = setTimeout(
			() => onChange(debouncedValue),
			debounceMillis
		);
		return () => clearTimeout(debounce);
	}, [value, onChange, debouncedValue, debounceMillis]);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		setDebouncedValue(e.target.value);
	};

	const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key !== 'Enter') {
			return;
		}

		if (debouncedValue === value) {
			return;
		}

		onChange(debouncedValue);
	};

	return (
		<TextField
			label="Search"
			{...props}
			value={debouncedValue}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: value ? (
					<InputAdornment position="end">
						<IconButton
							aria-label="clear-search"
							onClick={() => setDebouncedValue('')}
						>
							<ClearIcon />
						</IconButton>
					</InputAdornment>
				) : (
					<SearchIcon />
				),
			}}
		/>
	);
};

