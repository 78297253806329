import { ClickAwayListener, Paper, Stack, Typography } from "@mui/material";
import { useKeyPressListener } from "features/input/useKeyPressListener";
import { PageLayout } from "pages/components/PageLayout";
import React, { useCallback, useMemo, useState } from "react";
import { CreateCampaignDrawer } from "./CreateCampaignDrawer";
import { CampaignHeader, CampaignAction } from "./CampaignHeader";
import { CampaignGrid } from "./CampaignGrid";
import {
  useCampaigns,
  useDeleteCampaign,
  CampaignDetailDialog,
} from "features/campaign";
import { useCertifications } from "features/certification";
import { useCombinedQueryState } from "app/api";
import { EditCampaignDrawer } from "./EditCampaignDrawer";
import {
  ConfirmationDialog,
  ConfirmationType,
  useLoadingOverlay,
} from "features/modals";
import { useNotify } from "features/notifications";
import { ActiveOption } from "./ActiveMenuButton";

const CampaignPageComponent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Paper
    elevation={4}
    sx={{
      minWidth: 600,
      minHeight: 450,
      flexGrow: 1,
      borderRadius: 3,
      display: "flex",
      overflow: "hidden",
    }}
  >
    {children}
  </Paper>
);

export const Campaign: React.FC = () => {
  const { data: campaigns, ...campaignQuery } = useCampaigns();
  const { data: certifications, ...certificationQuery } = useCertifications();
  const { isLoading } = useCombinedQueryState(
    campaignQuery,
    certificationQuery
  );

  const [deleteCampaign] = useDeleteCampaign();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();
  const notify = useNotify();

  const [isSearching, setIsSearching] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [selectedCampaignId, setSelectedCampaignId] = useState<string>();
  const [showCampaignDetailDialog, setShowCampaignDetailDialog] =
    useState(false);

  const [drawerState, setDrawerState] = useState<"create" | "edit" | "closed">(
    "closed"
  );

  const selectedCampaign = useMemo(
    () => campaigns?.find((x) => x.id === selectedCampaignId),
    [campaigns, selectedCampaignId]
  );

  const filteredCampaigns = useMemo(() => {
    if (!campaigns) {
      return [];
    }

    let result = [...campaigns].filter((x) => x.isActive === isActive);

    const term = searchTerm.trim().toLowerCase();
    if (term.length > 0) {
      result = result.filter(
        (x) =>
          x.name.toLowerCase().includes(term) ||
          x.title.toLowerCase().includes(term) ||
          x.description.toLowerCase().includes(term)
      );
    }

    return result;
  }, [searchTerm, campaigns, isActive]);

  const handleEscapePress = useCallback(
    () => setSelectedCampaignId(undefined),
    [setSelectedCampaignId]
  );
  useKeyPressListener("Escape", handleEscapePress);

  // const handleClickAway = () => {
  //   if (drawerState === "closed") {
  //     setSelectedCampaignId(undefined);
  //   }
  // };

  const handleDrawerClose = () => {
    setDrawerState("closed");
  };

  const handleConfirm = async () => {
    try {
      showLoadingOverlay();
      if (showDeleteConfirmation) {
        setShowDeleteConfirmation(false);
        if (selectedCampaignId) {
          await deleteCampaign(selectedCampaignId);
        }
      }
    } catch {
      notify({
        title: "Failed to delete campaign.",
        severity: "error",
        persist: false,
      });
    } finally {
      hideLoadingOverlay();
    }
  };

  const handleConfirmationCancel = () => {
    setShowDeleteConfirmation(false);
  };

  const handleActionClick = (action: CampaignAction) => {
    // Will be using selectedCampaignId here, temporarly using console.log to supress linter warning that we're not using the variable
    console.log(selectedCampaignId);
    switch (action) {
      case CampaignAction.CreateCampaign:
        setDrawerState("create");
        break;
      case CampaignAction.EditCampaign:
        setDrawerState("edit");
        break;
      case CampaignAction.DeleteCampaign:
        setShowDeleteConfirmation(true);
        break;
      case CampaignAction.InspectCampaign:
        setShowCampaignDetailDialog(true);
        break;
    }
  };

  const handleOptionSelect = (option: ActiveOption) => {
    setIsActive(option === ActiveOption.Active);
  };

  return (
    <PageLayout
      title={
        <CampaignHeader
          isActive={isActive}
          searchTerm={searchTerm}
          setIsSearching={setIsSearching}
          onSearchChange={setSearchTerm}
          onActionClick={handleActionClick}
          onOptionSelect={handleOptionSelect}
          isEditable={selectedCampaignId !== undefined}
        />
      }
      component={CampaignPageComponent}
    >
      {/* <ClickAwayListener onClickAway={handleClickAway}> */}
      <Stack
        direction="row"
        sx={{
          overflow: "hidden",
          flex: "1",
        }}
      >
        <CampaignGrid
          loading={isLoading}
          campaigns={filteredCampaigns}
          selectedRow={selectedCampaignId}
          onRowSelect={(x) => setSelectedCampaignId(x)}
        />
        <CreateCampaignDrawer
          open={drawerState === "create"}
          onClose={handleDrawerClose}
        />
        <EditCampaignDrawer
          open={drawerState === "edit"}
          onClose={handleDrawerClose}
          campaign={selectedCampaign}
        />
        <ConfirmationDialog
          open={showDeleteConfirmation}
          onConfirm={() => handleConfirm()}
          onCancel={() => handleConfirmationCancel()}
          title="Delete"
          type={ConfirmationType.yesNo}
          text={
            <Typography>
              Are you sure you want to delete '{selectedCampaign?.name}'?
            </Typography>
          }
        />
        <CampaignDetailDialog
          open={showCampaignDetailDialog}
          campaign={selectedCampaign}
          onDismiss={() => setShowCampaignDetailDialog(false)}
        />
      </Stack>
      {/* </ClickAwayListener> */}
    </PageLayout>
  );
};
