import { Paper, Stack } from "@mui/material";
import { PageLayout } from "pages/components/PageLayout";
import React, { useMemo, useState } from "react";
import { useSpecialties, useUsers } from "features/user";
import { UserDetailGrid } from "./UserDetailGrid";
import { useCombinedQueryState } from "app/api";
import { UserDetailDialog } from "features/user/UserDetailDialog";
import { UserAction, UserHeader } from "./UserHeader";

const UserPageComponent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Paper
    elevation={4}
    sx={{
      minWidth: 600,
      minHeight: 450,
      flexGrow: 1,
      borderRadius: 3,
      display: "flex",
      overflow: "hidden",
    }}
  >
    {children}
  </Paper>
);

export const User: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [showUserDetailDialog, setShowUserDetailDialog] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: users, ...userQuery } = useUsers();
  const { data: specialities, ...specialityQuery } = useSpecialties();

  const { isLoading } = useCombinedQueryState(userQuery, specialityQuery);

  const selectedUser = useMemo(
    () => users?.users.find((x) => x.id === selectedUserId),
    [users, selectedUserId]
  );

  const filteredUsers = useMemo(() => {
    if (!users) {
      return [];
    }

    let result = [...users.users];

    const term = searchTerm.trim().toLowerCase();
    if (term.length > 0) {
      result = result.filter(
        (x) =>
          x.fullName?.toLowerCase().includes(term) ||
          x.email.toLowerCase().includes(term)
      );
    }

    return result;
  }, [searchTerm, users]);

  const handleActionClick = (action: UserAction) => {
    switch (action) {
      case UserAction.Inspect:
        setShowUserDetailDialog(true);
        break;
    }
  };

  return (
    <PageLayout
      title={
        <UserHeader
          searchTerm={searchTerm}
          setIsSearching={setIsSearching}
          onSearchChange={setSearchTerm}
          onActionClick={handleActionClick}
          isEditable={selectedUserId !== undefined}
        />
      }
      component={UserPageComponent}
    >
      <Stack
        direction="row"
        sx={{
          overflow: "hidden",
          flex: "1",
        }}
      >
        <UserDetailGrid
          loading={isLoading}
          users={filteredUsers}
          specialities={specialities}
          selectedRow={selectedUserId}
          onRowSelect={(x) => setSelectedUserId(x)}
        />
        <UserDetailDialog
          open={showUserDetailDialog}
          user={selectedUser}
          specialities={specialities}
          onDismiss={() => setShowUserDetailDialog(false)}
        />
      </Stack>
    </PageLayout>
  );
};
