import { appApi } from "app/api";
import {
  dataTransformSchema,
  requiredNumber,
  requiredString,
  requiredUuid,
} from "app/schema";
import * as yup from "yup";

export const subSpecialtyResourceSchema = yup.object({
  id: requiredUuid,
  specialtyId: requiredUuid,
  name: requiredString,
  createdAt: requiredString,
  updatedAt: requiredString,
});

export const specialtyResourceSchema = yup.object({
  id: requiredUuid,
  name: requiredString,
  category: requiredNumber,
  createdAt: requiredString,
  updatedAt: requiredString,
  subSpecialties: yup.array().of(subSpecialtyResourceSchema),
});

export interface Specialty
  extends yup.Asserts<typeof specialtyResourceSchema> {}
export interface SubSpecialty
  extends yup.Asserts<typeof subSpecialtyResourceSchema> {}

const specialtyApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listSpecialties: builder.query<Specialty[], void>({
      query: () => "specialties",
      extraOptions: {
        schema: dataTransformSchema(yup.array().of(specialtyResourceSchema)),
      },
    }),
  }),
});

export const {
  listSpecialties: { useQuery: useSpecialties },
} = specialtyApi.endpoints;
