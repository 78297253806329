import { Box, Stack } from "@mui/material";
import { Session } from "features/connections";
import { useLogger } from "features/logging";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Navigation } from "./Navigation";
import { SiteToolbar } from "./SiteToolbar";

const NAV_WIDTH = 180;

export interface AuthenticatedLayoutProps {
  session?: Session;
}

export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  session,
}) => {
  const log = useLogger("AuthenticatedLayout");
  const location = useLocation();

  // useSiteWindowTitle(session);
  // useSessionKeepAlive(session);

  if (!session) {
    log.info("Redirecting to login because there is not an active session.");
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <SiteToolbar navigationWidth={NAV_WIDTH} />
      <Stack direction="row" sx={{ flexGrow: 1, minHeight: 0 }}>
        <Navigation width={NAV_WIDTH} />
        <Box sx={{ flexGrow: 1, height: "100%", overflowY: "auto" }}>
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
};
