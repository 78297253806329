import React from 'react';
import moment from 'moment';

export type TimePrecision =
	'minutes' |
	'seconds' |
	'milliseconds';

export interface TimeProps {
	seconds: number;
	local?: boolean;
	precision?: TimePrecision;
}

export const Time: React.FC<TimeProps> = ({ seconds, local, precision = 'minutes' }) => {
	const time = local
		? moment.unix(seconds)
		: moment.unix(seconds).utc();

	switch (precision) {
		case 'minutes':
			return (<>
				{time.format('LT')}
			</>);
		case 'seconds':
			return (<>
				{time.format('LTS')}
			</>);
		case 'milliseconds':
			return (<>
				{time.format('h:mm:ss.SSS A')}
			</>);
	}
}