import { useConnection } from "features/connections";
import { Navigate } from "react-router-dom";
import { LoginForm, LoginFormState } from "./components/LoginForm";
import { useLogger } from "features/logging";
import { useLogin } from "features/authentication";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { AppLogo } from "app/AppLogo";

export const Login: React.FC = () => {
  const log = useLogger("Login");
  const [login, { status, error }] = useLogin();

  const { session, message, createSession } = useConnection();

  if (session) {
    return <Navigate to={"/campaigns"} replace={true} />;
  }

  const handleSubmit = async (form: LoginFormState) => {
    log.info(`Authenticating user...`);

    try {
      const result = await login({
        email: form.email,
        password: form.password,
      }).unwrap();

      console.log("result", result);
      createSession(result.userId, result.accessToken, result.refreshToken);
    } catch (err) {
      // The error is handled through state changes.
    }
  };

  const formError = error && "data" in error ? error.data?.title : message;

  const Logo = () => (
    <Box>
      <Box
        sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
      >
        <AppLogo width={60} height={60} />
      </Box>
      <Typography fontSize={36} color="text.primary">
        Medentials
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(/loginBackground.svg)`,
      }}
    >
      <Paper
        elevation={15}
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "white",
          paddingBottom: 9,
          px: 15,
          borderRadius: 2,
          pt: 4,
        }}
      >
        <Box>
          <Stack spacing={2} alignItems="center" sx={{ mt: 2 }}>
            <Logo />
            <Typography fontSize={16} fontWeight="light">
              Please login to continue
            </Typography>
            <LoginForm
              onSubmit={handleSubmit}
              status={status}
              error={formError}
            />
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};
