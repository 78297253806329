import React from "react";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Login } from "pages/login/Login";
import { AuthenticatedLayout } from "pages/components/AuthenticatedLayout";
import { Dashboard } from "pages/dashboard/Dashboard";
import { useConnection, useExistingSessionCheck } from "features/connections";
import { LoadingIndicator } from "app/LoadingIndicator";
import { Campaign } from "pages/campaign/Campaign";
import { ToastContainer } from "features/notifications";
import { GlobalLoadingOverlay } from "features/modals";
import { ExportUrl } from "pages/exports/pages/ExportUrl";
import { User } from "pages/user/User";
import PrivacyPage from "pages/splash/PrivacyPage";
import { Recipient } from "pages/recipient/Recipient";
import { SplashNew } from "pages/splash/SplashNew/SplashNew";

const App: React.FC = () => {
  const { session } = useConnection();
  const { isComplete: sessionCheckComplete } = useExistingSessionCheck();

  if (!sessionCheckComplete) return <LoadingIndicator />;

  return (
    <Router>
      <Routes>
        {/* <Route index element={<Navigate to="login" replace={true} />} /> */}
        <Route index element={<SplashNew />} />
        <Route path="login" element={<Login />} />
        <Route path="privacy" element={<PrivacyPage />} />
        <Route path="exports/:exportId" element={<ExportUrl />} />
        <Route element={<AuthenticatedLayout session={session} />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="campaigns" element={<Campaign />} />
          <Route path="users" element={<User />} />
          <Route path="recipients" element={<Recipient />} />
        </Route>
      </Routes>
      <Outlet />

      <ToastContainer />
      <GlobalLoadingOverlay />
    </Router>
  );
};

export default App;
