import { useDispatch } from "app/store.helper";
import moment from "moment";
import React, { useCallback } from "react";
import {
  createNotification,
  NotificationState,
  ToastConfig,
  updateNotification,
} from "./notificationsSlice";
import { actionRepository } from "./actionRepository";

export interface NotifyToastArgs extends ToastConfig {
  actions?: React.ReactNode;
}

export interface NotifyArgs {
  id?: string;
  title: string;
  severity?: NotificationState["severity"];
  actions?: React.ReactNode;
  toast?: NotifyToastArgs | boolean;
  persist?: boolean;
}

export type NotifyFunc = (args: NotifyArgs) => string;

const isToastArgs = (toast: NotifyArgs["toast"]): toast is NotifyToastArgs =>
  typeof toast === "object";

export const useNotify = (): NotifyFunc => {
  const dispatch = useDispatch();

  const notify = useCallback(
    ({
      id,
      title,
      severity,
      actions,
      toast = true,
      persist = true,
    }: NotifyArgs) => {
      const update = id !== undefined;
      if (id === undefined) {
        id = crypto.randomUUID();
      }

      const toastConfigured = isToastArgs(toast);
      const toastState = !toastConfigured
        ? toast
        : {
            title: toast.title,
          };

      if (actions) {
        actionRepository.set(id, {
          main: actions,
          toast: toastConfigured ? toast.actions : undefined,
        });
      } else if (update) {
        actionRepository.delete(id);
      }

      if (update) {
        dispatch(
          updateNotification({
            id,
            timestamp: moment().unix(),
            title,
            severity,
            toast: toastState,
          })
        );
      } else {
        dispatch(
          createNotification({
            id,
            timestamp: moment().unix(),
            toast: toastState,
            persist,
            title,
            severity,
          })
        );
      }

      return id;
    },
    [dispatch]
  );

  return notify;
};
