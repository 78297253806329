import { Stack, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";

export default function PrivacyPage() {
  const md = `## PRIVACY POLICY

  This Privacy Notice applies to your use of our Services and covers only information collected
  in connection with your access to and use of our Services. Please read this Privacy Notice
  carefully. By continuing to interact with our Services, you are consenting to the practices
  described in this Privacy Notice.
  
  **INFORMATION WE COLLECT ABOUT YOU**
  
  We need to collect information about you to provide you with the Services or the support you
  request. Specifically, we collect (and/or have collected during at least the 12-month period
  preceding the effective date of this Privacy Notice) the categories of personal information
  described below. Additionally, you can choose to voluntarily provide information to us.
  
  **Information You Provide to Medentials**
  
  We collect information you provide when you apply, access , or signup for a Medentials
  account, go through our identity or account verification process, authenticate into your
  account, communicate with us, or otherwise use our Services.
  
  We collect the following categories of information about you when you use our Services, including:
  
  1. **Identification Information:** Your name, email address, address, zip code, phone
      number, level of highest education, professional length of experience, professional
      healthcare setting and preferred displayed credentials.
  2. **Additional Identification Information for Verified Users:** Your credentialing
      documents (CV/Resume, Licenses, Education details, Memberships, Certifications
      and Insurances) via photograph if you choose to share such information.
  3. **Recipient Information:** To provide you with the Service, we will also need to
      collect information about the intended recipient of exported files you request us to
      send. You will be asked to provide contact details of your intended recipient by
      entering a Full Name, Organization, Title and Email address into the App.
  
  ## SOURCES OF INFORMATION COLLECTED FROM YOUR USE
  
  We collection formation about you from the following categories of sources :
  
  1. You directly when you submit information to us or allowus to access information.
  2. Your devices and show you interact with Medentials.
  3. Any affiliates.
  
  
  4. Identity Verification. Information from third-party verification services (e.g.,
      identity verification agencies and companies which provide third party analytics.
  
  **HOW YOUR INFORMATION IS USED**
  
  We may collect , use , and share information about you for the following reasons :
  
  Providing, Improving, and Developing our Services
  
  1. Determining whether the Services are available in your country.
  2. Providing you with the features you choose to use.
  3. Providing, maintaining, and improving our Services.
  4. Developing new products and services.
  5. Delivering the information and support you request through the use of our Services.
  6. Improving, personalizing, and facilitating your use of our Services, content and
      applications.
  7. Measuring, tracking, and analyzing trends and usage in connection with your use or
      the performance of our Services.
  8. Otherwise providing you with the products and features you choose to use.
  
  
  **Information To Protect and Maintain a Trusted Service**
  
  1. Conducting investigations, complying with, and enforcing applicable laws,
      regulations, legal requirements, and industry standards, and responding to lawful
      requests for information from the government or to valid legal process.
  2. Debugging to identify and fix errors that impair how our Services function.
  3. Ensuring compliance with Medentials’s Terms and Services as well as other
      applicable agreements or policies.
  4. Protecting every user’s rights or property, and the security and integrity of our
      Services.
  5. Verifyng or maintaining the quality and safety of our Services.
  6. Verifying your identity via email verification
  
  **Advertising and Marketing**
  
  1. Marketing our Services to you.
  2. Communicating with you about opportunities, products, services, contests,
      promotions, discounts, incentives, surveys, and rewards offered by us and select
      partners.
  
  You have the right to "Opt" out of any Push Notifications or emails.
  
  **Other Uses**
  
  1. For any other reason we may tell you about from time to time.
  
  **WHEN AND WHO YOUR INFORMATION IS SHARED WITH**
  
  We may share the information described in the “Information We Collect About You” section
  with the following categories of service providers and third parties
  
  **With our Service Providers**
  
  1. With service providers who help us provide, maintain, and improve Medentials.
  
  **Business Transfers and Corporate Changes**
  
  1. In the event of a change in corporate status, merger, acquisition, or any other event
      that changes the operating or ownership structure of Medentials, in order to
      complete and conduct due diligence.
  
  **Compliance with Law**
  
  1. If there is a reasonable belief that disclosure is necessary (i) to comply with any
      applicable law, regulation, legal process or governmental request (ii) to establish,
      exercise or defend our legal rights; (iii) to enforce or comply with Medentials’s
      Terms and Services, or other applicable agreements or policies; (iv) to protect
      Medentials or our customers’ rights or property, or the security or integrity of our
      Services; (v) for an investigation of suspected or actual illegal activity; or (vi) to
      protect us, users of our Services or the public from harm, fraud, or potentially
      prohibited or illegal activities.
  
  **With Your Consent**
  
  1. With your consent at your direction or as described at the time you agree to share
      when you authorize a third-party application or website to access your information.
  
  **Third-Party Service Providers**
  
  1. With other companies who deliver services on our behalf, including via the use of
      cookies and similar automated technologies, and third-party analytics.
  
  **Aggregated and Anonymized Information**
  
  1. We also may share aggregate and / or anonymized information that does not
      specifically identify you or any individual user of our Services.
  
  **COOKIES AND OTHER AUTOMATED TECHNOLOGIES**
  
  When you interact with Medentials, or open emails we send you, we obtain certain
  information using automated technologies, such as cookies, web server logs, web beacons,
  and other technologies. A “cookie” is a text file that websites send to a visitor’s computer or
  other internet-connected device to uniquely identify the visitor’s browser or to store
  
  
  information or settings in the browser. A “web beacon,” also known as an internet tag, pixel
  tag, or clear GIF, is a tiny graphic image that may be used in our websites or emails.
  
  We use these automated technologies to collect your device information, internet activity
  information, and inferences as described above. These technologies help Medentials and its
  processors/third-party service providers to:
  
  1. Remember your information so you do not have to re-enter it.
  2. Track and understand how you use and interact with our online services and emails.
  3. Tailor our online services to your preferences.
  4. Measure how useful and effective our services and communications are to you.
  5. Otherwise manage and enhance our products and services.
  
  Medentials sets some of these automated technologies ourselves, while others are set by third
  party service providers. This may include third-party web analytics services (described in the
  “Third-Party Analytics Services” section), which use automated technologies to help
  Medentials evaluate how customers use of our websites.
  
  You can opt out of certain automated technologies, including cookies. Please note , however,
  that without cookies, some the features of our online services may not work properly. If you
  use a mobile device, you can manage how your device and browser share certain device data
  by changing the privacy and security settings on your mobile device.
  
  **HOW LONG YOUR INFORMATION IS MAINTAINED**
  
  Your in formation is maintained for as long as reasonably necessary to provide you the
  Services, including during the entire time that you use Medentials, or to comply with
  applicable law. Please note, after you deactivate your account, we can retain copies of
  information about you and any transactions or Services in which you may have participated
  for a period of time that is consistent with applicable law, any applicable statute of limitations,
  or as we believe is reasonably necessary to comply with applicable law, regulation, legal
  process, or governmental request, to resolve disputes, to address problems with our Services,
  to assist with investigations, to enforce Medentials’s Terms and Services, or other applicable
  agreements or policies, or to take any other actions consistent with applicable law.
  
  **CUSTOMER CHOICES IN RELATION TO PRIVACY**
  
  **Personal Information**
  
  You can access , change , or correct information that uou have provided by logging in to your
  Medentials account at any time on the App.
  
  
  **Promotional Communications**
  
  You can opt out of receiving in - app push notifications containing promotional messages from
  Medentials by following the instructions on screen or by following the instructions in those
  messages. If you decide to opt out, we may still send you non-promotional communications
  in-app, or via email and text, such as messages about your account.
  
  ## CALIFORNIA RESIDENTS
  
  Residents of California have the following additional rights:
  
  **Right to Receive Information**
  
  You may have the right to request , up to twice in a twelve - month(12) period , to see the
  following information about the personal information we have collected about you during the
  past twelve (12) months:
  
  1. The categories and specific pieces of personal information we have collected about
      you.
  2. The categories of sources from which we collected the personal information.
  3. The business or commercial purpose for which we collected the personal
      information.
  4. The categories of third parties with whom we shared the personal information.
  5. The categories of personal information about you that we disclosed for a business
      purpose, and the categories of third parties to whom we disclosed that information
      for a business purpose.
  
  **How to Request Your Information**
  
  If you live in California and seek to obtain the information that we have collected from your
  use of Medentials, send a request to exercise one of your above rights online at **(NEED TO
  LIST URL)** or by contacting us as indicated in the “How to Contact Us” section below.
  
  In order to help protect your privacy and maintain security, we will require you to verify your
  identity and will require you to be logged in to your Medentials App account before granting
  you access to your personal information to fulfil your request.
  
  To designate an authorized agent to make a request on your behalf as permitted by law, you
  will be required to provide the authorized agent written permission to do so and additionally
  must verify your identity with us.
  
  When applicable and permitted under the law, we may charge you a reasonable fee to process
  your request.
  
  
  California law provides the right to ask if we disclose your personal information to third
  parties for direct marketing purposes or if we sell your information to third parties.
  Medentials does not disclose or sell your information for either purpose, however in
  compliance with the applicable law we provide this statement as an additional notice of your
  rights.
  
  **Right of Deletion**
  
  You have the right to ask us to delete the personal information we have collected from you ,
  with the limitation that applicable law may dictate that we maintain certain user information.
  Please note that you may no longer be able to use Medentials if you delete your personal
  information.
  
  **Right of Non-Discrimination**
  
  Should you exercise your rights under this Privacy Policy, you have the right to be free from
  discrimination. We will not discriminate against you, deny, charge different prices for, or
  provide a different quality of goods or services if you choose to exercise these rights.
  
  **Exceptions to These Rights**
  
  Certain exceptions to the rights described above are prescribed by California law. We reserve
  the right to avail ourselves of these exceptions where applicable.
  
  
  ## MINOR’S PERSONAL INFORMATION
  
  Medentials’s policy is to prohibit the use of our services to anyone under the age of 18.
  Should we determine that any information has been provided to Medentials by a minor, that
  information will be immediately deleted unless otherwise required by law to be maintained.
  
  **HOW WE SECURE**
  
  We take every precaution to secure your personal information , however, even through our security measures we make no guarantees or warrant that your information will be secure at all times. We reserve the right to update our security procedures at any time without notice to you.
  
  **STORAGE OF INFORMATION**
  
  We may utilize third party service provider to store and maintain user information as necessary, and in an effort to ensure secure maintenance of your information.
  
  **CHANGES TO OUR PRIVACY POLICY**
  
  This Privacy Policy may be amended from time to time by posting an amended version and
  updating the “Effective Date” above. The amended version will be effective on the “Effective
  Date” listed. You will be provided with reasonable prior notice of material changes in how we
  use your information, which may include notification by Medentials through the App, website,
  or by email. Continued use of Medentials and our Services shall serve as consent to the
  amended Privacy Policy. If you disagree with the amended Privacy Policy, you may cancel
  your account at any time.
  
  **GDPR SPECIFIC RIGHT TO ERASURE**
  
  You are entitled to request us to erase any personal data we hold about you under EU General
  Data Protection Regulation (GDPR).
  
  It is Medentials’s policy to attempt to respond promptly and in any event within one month of the
  following:
  
  1. Our receipt of your written request; or
  2. Our receipt of any further information we may ask you to provide to enable us to comply
      with your request, whichever happens to be later.
  
  The information you supply in this form will only be used for the purposes of identifying the
  personal data you are requesting that we erase and responding to your request. You are not
  obliged to complete this form to make a request but doing so will make it easier for us to process
  your request quickly. The following is the information that we must receive in order to consider
  and process your request:
  
  
  **SECTION 1: Details of the person requesting information**
  
  Full Legal Name:
  Address:
  Telephone Number:
  Email Address:
  
  **SECTION 2: Are you the data subject?**
  
  Please provide the appropriate information based upon whether you are the data subject or acting
  on behalf of the data subject:
  
  **YES** : I am the data subject. I enclose proof of my identity:
  
  Please provide proof of identity in the form of a copy of your passport photo page, driver’s
  license, official identity card, or birth certificate, and a current utility bill confirming your
  address (do not provide any original documents).
  
  **NO** : I am acting on behalf of the data subject. I have enclosed the data subject’s written authority
  and proof of the data subject’s identity and my own identity:
  
  Original letter of authorization from the data subject designating a third-party to make this
  request. Please provide proof of identity in the form of a copy of your passport photo page,
  driver’s license, official identity card, or birth certificate, and a current utility bill confirming
  your address (do not provide any original documents).
  
  If we are not satisfied you are who you claim to be, we reserve the right to refuse to grant your
  request.
  
  **SECTION 3: Reason for erasure request**
  
  Given the sensitive nature of erasing personal data, GDPR Article 17(1) requires certain
  conditions to be met before a request may be considered. Please supply us with the reason you
  wish your data to be erased and please attach any justifying documents to this one.
  
  Please provide us with the appropriate reason you are seeking erasure and include the reason in
  your request:
  
  1. You feel your personal data is no longer necessary for the purposes for which we
      originally collected it.
  2. You no longer consent to our processing of your personal data.
  3. You object to our processing of your personal data as is your right under Article 21 of
      the GDPR.
  4. You feel your personal data has been unlawfully processed.
  5. You feel we are subject to a legal obligation of the EU or Member State that requires
      the erasure of your personal data.
  6. You are a child, you represent a child, or you were a child at the time of the data
      processing, and you feel your personal data was used to offer you information society
      services.
  
  **SECTION 4: What information do you wish to erase?**
  
  
  Please describe the information you wish to erase. Please provide any relevant details you think
  will help us to identify the information. Providing the URL for each link you wish to be removed
  would be helpful.
  
  Also, please explain, if it is not abundantly clear, why the linked page is about you or the person
  you are representing on this form.
  
  Please note that. In certain circumstances, where erasure would adversely affect the freedom of
  expression, contradict a legal obligation, act against the public interest in the area of public
  health, act against the public interest in the area of scientific or historical research, or prohibit the
  establishment of a legal defense or exercise of other legal claims, we may not be able to erase the
  information you requested in accordance with article 17(3) of the GDPR. In such cases you will
  be informed promptly and given full reasons for that decision.
  
  While in most cases we will be happy to erase the personal data you request, we nevertheless
  reserve the right, in accordance with Article 12(5) of the GDPR, to charge a fee or refuse the
  request if it is “manifestly unfounded or excessive.” However, we will make every effort to
  provide you with the erasure of your personal data if suitable.
  
  **SECTION 5: Declaration (Please include this declaration with your request):**
  
  Please note that any attempt to mislead may result in prosecution.
  
  I confirm that I have read and understood the terms of this subject access form and certify that
  the information given in this application to ______________ is true. I understand that it is
  necessary for ________________ to confirm my/the data subject’s identity and it may be
  necessary to obtain more detailed information in order to locate the correct personal data.
  
  **Signed: _________________________________ Date: _____________________________**
  
  **Documents which must accompany this application:**
  
  1. Evidence of your identity as enumerated above.
  2. Evidence of the data subject’s identity (if different from above).
  3. Authorization from the data subject to act on their behalf (if applicable).
  4. Justification for erasure of data.
  `;

  return (
    <Stack sx={{ background: "#fff" }} alignItems="center">
      <Stack sx={{ pt: 5, pl: 3, pr: 3, pb: 5, maxWidth: "930px" }}>
        <Typography
          sx={{ fontSize: "38px", lineHeight: "44px", color: "#0c3800", mb: 4 }}
        >
          Medentials Privacy Policy
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#333",
          }}
        >
          <ReactMarkdown>{md}</ReactMarkdown>
        </Typography>
      </Stack>
    </Stack>
  );
}
