import { appApi } from "app/api";
import {
  nullableString,
  dataTransformSchema,
  requiredNumber,
  requiredString,
  requiredUuid,
} from "app/schema";
import * as yup from "yup";

export const recipientResourceSchema = yup.object({
  id: requiredUuid,
  userId: requiredUuid,
  name: requiredString,
  email: yup.string().email().required(),
  organization: nullableString,
  title: nullableString,
  createdAt: requiredString,
  updatedAt: requiredString,
});

const recipientListSchema = yup.object({
  recipients: yup.array().of(recipientResourceSchema).required(),
  total: requiredNumber,
});

export interface Recipient
  extends yup.Asserts<typeof recipientResourceSchema> {}
export interface RecipientList
  extends yup.Asserts<typeof recipientListSchema> {}

const recipientApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    listRecipients: builder.query<RecipientList, void>({
      query: () => "recipients/all",
      extraOptions: {
        schema: dataTransformSchema(recipientListSchema),
      },
    }),
  }),
});

export const {
  listRecipients: { useQuery: useRecipients },
} = recipientApi.endpoints;
