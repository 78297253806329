import { Box, Stack, Typography } from "@mui/material";
import { ShareIcons } from "./ShareIcons";

export const SplashHeader = () => {
  return (
    <Stack
      sx={{
        height: { md: "100vh" },
        color: "#fff",
        background: "#E8FAFB",
        p: { xs: 3, md: 0 },
        pb: { xs: 0, md: 6 },
      }}
      alignItems="center"
      justifyContent={{ md: "center" }}
    >
      <Box
        sx={{
          background: "linear-gradient(0deg, #77C1BC, #34A595)",
          width: "100%",
          height: "20px",
          position: "absolute",
          top: 0,
        }}
      />

      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={{ xs: 0, md: 10, width: "100%" }}
      >
        <Box
          component="img"
          sx={{
            display: { xs: "none", md: "block" },
            alignSelf: "end",
            width: 800,
          }}
          src="header_hand.png"
        />
        <Stack
          sx={{
            alignItems: { xs: "center", md: "start", maxWidth: 600 },
            pt: 5,
            pb: 3,
          }}
          spacing={1}
        >
          <Box
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <img src="mobile_header.png" width={300} height={450} alt="" />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "61px", md: "110px" },
              fontWeight: "800",
              fontFamily: '"sofia-pro", "Roboto", sans-serif',
              color: "#000",
            }}
          >
            Medentials
          </Typography>
          <Typography
            sx={{
              color: "#4F5E71",
              fontSize: { xs: "19px", md: "27px" },
              fontWeight: "700",
              p: { xs: 1, md: 0 },
              pb: { xs: 3, md: 3 },
              lineHeight: { md: "50px" },
              fontFamily: '"sofia-pro", "Roboto", sans-serif',
            }}
          >
            Free app for all healthcare providers to organize credentialing
            documents
          </Typography>
          <ShareIcons light={false} />
        </Stack>
      </Stack>
    </Stack>
  );
};
