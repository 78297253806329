import moment from 'moment';
import React from 'react';
import { Date } from './Date';
import { Time, TimePrecision } from './Time';

export interface DateTimeProps {
	seconds: number;
	includeWeekday?: boolean;
	short?: boolean;
	format?: string;
	local?: boolean;
	precision?: TimePrecision;
}

export const DateTime: React.FC<DateTimeProps> = (props) => {
	if (props.format) {
		const datetime = props.local
			? moment.unix(props.seconds)
			: moment.unix(props.seconds).utc();

		return (<>
			{datetime.format(props.format)}
		</>)
	}

	return (<>
		<Date {...props} /> <Time {...props} />
	</>)
}