// import { SearchInput } from "features/input";
import { Delete, Edit, Info } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { SearchInput } from "features/input";
import { ActiveMenuButton, ActiveOption } from "./ActiveMenuButton";

export enum CampaignAction {
  CreateCampaign,
  EditCampaign,
  DeleteCampaign,
  InspectCampaign,
}

const getActionIcon = (action: CampaignAction) => {
  switch (action) {
    case CampaignAction.CreateCampaign:
      return <Edit />;
    case CampaignAction.EditCampaign:
      return <Edit />;
    case CampaignAction.DeleteCampaign:
      return <Delete />;
    case CampaignAction.InspectCampaign:
      return <Info />;
  }
};

const getActionLabel = (action: CampaignAction) => {
  switch (action) {
    case CampaignAction.CreateCampaign:
      return "Create";
    case CampaignAction.EditCampaign:
      return "Edit";
    case CampaignAction.DeleteCampaign:
      return "Delete";
    case CampaignAction.InspectCampaign:
      return "Summary";
  }
};

export interface CampaignHeaderProps {
  isActive: boolean;
  searchTerm: string;
  setIsSearching: (isSearching: boolean) => void;
  onSearchChange: (searchTerm: string) => void;
  onActionClick: (action: CampaignAction) => void;
  onOptionSelect(option: ActiveOption): void;
  isEditable: boolean;
}

export const CampaignHeader: React.FC<CampaignHeaderProps> = ({
  isActive,
  searchTerm,
  setIsSearching,
  onSearchChange,
  onActionClick,
  onOptionSelect,
  isEditable,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        mt: 2,
        pb: 1,
      }}
    >
      {(isEditable
        ? [
            CampaignAction.CreateCampaign,
            CampaignAction.EditCampaign,
            CampaignAction.DeleteCampaign,
            CampaignAction.InspectCampaign,
          ]
        : [CampaignAction.CreateCampaign]
      ).map((action) => (
        <Button
          key={action}
          size="small"
          variant="text"
          sx={{ fontWeight: "bold" }}
          startIcon={getActionIcon(action)}
          onClick={(_) => onActionClick(action)}
        >
          {getActionLabel(action)}
        </Button>
      ))}

      <Box sx={{ flex: 1 }} />
      <ActiveMenuButton
        isActive={isActive}
        options={[ActiveOption.Active, ActiveOption.Inactive]}
        onOptionSelect={onOptionSelect}
      />
      <SearchInput
        label="Filter by name, title, description"
        size="small"
        sx={{ width: 400 }}
        value={searchTerm}
        onBlur={() => setIsSearching(false)}
        onChange={(value) => onSearchChange(value)}
        fullWidth
      />

      {/* <SearchInput
        label="Filter by name"
        size="small"
        sx={{ width: 400 }}
        value={searchTerm}
        onChange={(value) => onSearchChange(value)}
        fullWidth
      /> */}
    </Stack>
  );
};
