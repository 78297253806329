import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LoadingIndicator } from "app/LoadingIndicator";
import { column } from "features/datagrid";
import React, { useMemo } from "react";
import { User } from "features/user";
import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const EmptyUser: React.FC = () => {
  return <Typography sx={{ fontSize: 16 }}>No Users</Typography>;
};

export interface UserInfo {
  id: string;
  name: string;
}

const columns: GridColDef[] = [
  column.string({
    width: 300,
    field: "fullName",
    headerName: "Name",
    renderCell: (cell) => {
      return (
        <Stack>
          <Typography>{cell.row.fullName}</Typography>
          <Typography typography="caption">ID: {cell.row.id}</Typography>
        </Stack>
      );
    },
  }),
  column.string({
    width: 300,
    field: "email",
    headerName: "Email",
  }),
  column.boolean({
    width: 100,
    field: "isDeviceAvailable",
    headerName: "Device",
    sortable: false,
    renderCell: (cell) => {
      return (
        <CircleIcon
          sx={{ color: cell.value ? "green" : "#A30000", width: 16 }}
        />
      );
    },
  }),
];

export const UserGrid: React.FC<{
  users?: User[];
  loading: boolean;
}> = ({ users, loading }) => {
  const rows = useMemo(() => {
    if (!users) {
      return [];
    }

    return users.map((x) => {
      return {
        id: x.id,
        fullName: x.fullName,
        email: x.email,
        isDeviceAvailable: x.isDeviceAvailable,
      };
    });
  }, [users]);

  if (rows.length === 0) {
    return <EmptyUser />;
  }

  console.log("rows", rows);
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LoadingIndicator,
      }}
      getRowHeight={() => 50}
      loading={loading}
      getRowSpacing={() => ({ top: 5, bottom: 15 })}
      hideFooter
      sx={{
        border: 0,
        "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
          {
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
      }}
    />
  );
};
