import { Box, Stack } from "@mui/material";
import { SearchInput } from "features/input";

export interface RecipientHeaderProps {
  searchTerm: string;
  setIsSearching: (isSearching: boolean) => void;
  onSearchChange: (searchTerm: string) => void;
}

export const RecipientHeader: React.FC<RecipientHeaderProps> = ({
  searchTerm,
  setIsSearching,
  onSearchChange,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        mt: 2,
        pb: 1,
      }}
    >
      <Box sx={{ flex: 1 }} />
      <SearchInput
        label="Filter by name or email"
        size="small"
        sx={{ width: 400 }}
        value={searchTerm}
        onBlur={() => setIsSearching(false)}
        onChange={(value) => onSearchChange(value)}
        fullWidth
      />
    </Stack>
  );
};
