import { Box } from "@mui/material";
import { ValuePropDesktop } from "./ValuePropDesktop";
import { ValuePropMobile } from "./ValuePropMobile";

export interface ValueProps {
  items: ValueProp[];
}

export interface ValueProp {
  index: number;
  title: string;
  description: string;
}

const VALUE_PROPS = [
  {
    index: 0,
    title: "Easily Load Documents",
    description:
      "Use the file attach or photo snap feature to easily load CV/Resume, Professional Licenses, Education/Diplomas, Memberships, Certifications and Insurance documents.",
  },
  {
    index: 1,
    title: "Assign Expiration Dates",
    description:
      "For documents with assigned expiration dates, users will receive push notification reminders and inbox messages at 60, 30 and 7 days prior to expiration.",
  },
  {
    index: 2,
    title: "Effortlessly Export Documents",
    description:
      "Use our export feature to send partial or entire credentialing documentation packet to your credentialing contacts. Now with optional password protection.",
  },
];

export default function ValuePropPage() {
  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ValuePropMobile items={VALUE_PROPS} />
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <ValuePropDesktop items={VALUE_PROPS} />
      </Box>
    </>
  );
}
