import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { FormTextField } from "features/form";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  email: yup
    .string()
    .email("Invalid email.")
    .required("Please enter an email.")
    .default(""),
  password: yup.string().required("Please enter a password.").default(""),
});

export interface LoginFormState extends yup.Asserts<typeof schema> {}

export type LoginFormProps = {
  onSubmit: (state: LoginFormState) => void;
  status: QueryStatus;
  error?: string;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  status,
  error,
}) => {
  const { control, handleSubmit } = useForm<LoginFormState>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  });

  const showError = error || status === QueryStatus.rejected;

  return (
    <Stack
      spacing={3}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        alignItems: "center",
        "& .MuiTextField-root": { width: "45ch" },
      }}
    >
      <FormTextField
        control={control}
        name="email"
        label="Email"
        focused
        autoComplete="false"
      />
      <FormTextField
        control={control}
        name="password"
        label="Password"
        type="password"
        focused
        autoComplete="current-password"
      />

      {showError && (
        <Box>
          <Typography color="error" fontSize={16}>
            {error ?? "Unable to establish a connection to the server."}
          </Typography>
        </Box>
      )}

      <Button
        type="submit"
        variant="contained"
        size="large"
        disabled={status === QueryStatus.pending}
        sx={{ borderRadius: 1, px: 12 }}
      >
        Login
      </Button>
    </Stack>
  );
};
