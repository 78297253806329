import { Box, Stack } from "@mui/material";
import React from "react";

export interface ShareIconProps {
  light?: boolean;
}

export const ShareIcons: React.FC<ShareIconProps> = ({ light = true }) => {
  return (
    <Stack direction="row" spacing={2}>
      <a
        href="https://apps.apple.com/us/app/medentials/id1664257821"
        target="_blank"
        rel="noreferrer"
      >
        <Box
          component="img"
          sx={{ width: { xs: 160, md: 180 } }}
          src={light ? "ios.svg" : "ios.dark.svg"}
          alt="ios"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.medentials"
        target="_blank"
        rel="noreferrer"
      >
        <Box
          component="img"
          sx={{ width: { xs: 160, md: 180 } }}
          src={light ? "google.svg" : "google.dark.svg"}
          alt="google"
        />
      </a>
    </Stack>
  );
};
