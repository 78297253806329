import {
  Campaign,
  useCreateAds,
  useUpdateCampaign,
  useUploadCampaignImage,
} from "features/campaign";
import { useCampaignFormToCampaignMapper } from "./CampaignForm/useCampaignFormToCampaignMapper";
import { useMemo } from "react";
import { mapFromCampaign } from "./CampaignForm/mapFromCampaign";
import { CampaignForm, CampaignFormFields } from "./CampaignForm";
import { FormDrawer } from "features/modals";
import { useNotify } from "features/notifications";
import { Typography } from "@mui/material";
import { useLoadingOverlay } from "features/modals";

export interface EditMaintenanceDrawerProps {
  open: boolean;
  onClose: () => void;
  campaign?: Campaign;
}

export const EditCampaignDrawer: React.FC<EditMaintenanceDrawerProps> = ({
  open,
  onClose,
  campaign,
}) => {
  const notify = useNotify();
  const [updateCampaign] = useUpdateCampaign();
  const [createAds] = useCreateAds();
  const [uploadCampaignImage] = useUploadCampaignImage();
  const [mapToRequest] = useCampaignFormToCampaignMapper();
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const defaultValues = useMemo(
    () => campaign && mapFromCampaign(campaign),
    [campaign]
  );

  if (!campaign) {
    return null;
  }

  const handleSubmit = async (values: CampaignFormFields) => {
    try {
      const request = {
        ...mapToRequest(values),
        id: campaign.id,
      };

      await updateCampaign(request).unwrap();
      const { files } = values;
      if (files && files.length > 0) {
        const [file] = files;
        const formData = new FormData();
        formData.append("file", file);
        await uploadCampaignImage({ id: campaign.id, formData });
      }

      onClose();
    } catch (err) {
      notify({
        title: `Failed to update the campaign '${values.name}'.`,
        persist: false,
        severity: "error",
      });
    }
  };

  const handleCreateAds = async () => {
    try {
      showLoadingOverlay();
      await createAds(campaign.id);
      notify({
        title: "Successfully executed campaign.",
        persist: false,
      });
    } catch (err) {
      console.log("err", err);
      notify({
        title: "Error with creating ads.",
        persist: false,
        severity: "error",
      });
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <FormDrawer open={open} title="Edit Campaign" onClose={onClose}>
      <Typography variant="caption" sx={{ pl: 4, pt: 2, display: "block" }}>
        ID: {campaign.id}
      </Typography>
      <CampaignForm
        onSubmit={handleSubmit}
        onCreateAds={handleCreateAds}
        onCancel={onClose}
        defaultValues={defaultValues}
      />
    </FormDrawer>
  );
};
