import { useLoadingOverlay } from 'features/modals';
import { useEffect, useRef } from 'react';
import { FieldValues, FormState } from 'react-hook-form';

export function useFormSubmittingOverlay<T extends FieldValues>({ isSubmitting }: FormState<T>): void {
	const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();
	const triggeredRef = useRef<boolean>(false);
	
	useEffect(() => {
		const triggered = triggeredRef.current;

		if (!triggered && isSubmitting) {
			showLoadingOverlay();
			triggeredRef.current = true;
		} else if (triggered && !isSubmitting) {
			hideLoadingOverlay();
			triggeredRef.current = false;
		}
	}, [
		isSubmitting,
		showLoadingOverlay,
		hideLoadingOverlay
	])
}