import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { LoadingIndicator } from "app/LoadingIndicator";
import { column } from "features/datagrid";
import React, { useMemo } from "react";
import { Recipient } from "features/recipient";
import { Typography } from "@mui/material";

const EmptyRecipient: React.FC = () => {
  return <Typography sx={{ fontSize: 16 }}>No Recipients</Typography>;
};

export interface RecipientInfo {
  id: string;
  name: string;
}

const columns: GridColDef[] = [
  column.string({
    width: 160,
    field: "name",
    headerName: "Name",
  }),
  column.string({
    width: 400,
    field: "email",
    headerName: "Email",
  }),
  column.string({
    width: 200,
    field: "organization",
    headerName: "Organization",
  }),
  column.string({
    width: 300,
    field: "title",
    headerName: "Title",
  }),
];

export interface RecipientDetailGridProps {
  recipients?: Recipient[];
  loading: boolean;
  selectedRow?: string;
  onRowSelect?: (recipientId?: string) => void;
}

export const RecipientDetailGrid: React.FC<RecipientDetailGridProps> = ({
  recipients,
  loading,
  selectedRow,
  onRowSelect,
}) => {
  const rows = useMemo(() => {
    if (!recipients) {
      return [];
    }

    return recipients.map((x) => {
      return {
        id: x.id,
        name: x.name,
        email: x.email,
        organization: x.organization ?? "N/A",
        title: x.title ?? "N/A",
      };
    });
  }, [recipients]);

  const handleRowSelect = (selection: GridSelectionModel) => {
    const [rowId] = selection;
    if (!rowId) {
      onRowSelect?.(undefined);
      return;
    }

    onRowSelect?.(rowId as string);
  };

  if (rows.length === 0) {
    return <EmptyRecipient />;
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LoadingIndicator,
      }}
      getRowHeight={() => 50}
      loading={loading}
      selectionModel={selectedRow ? [selectedRow] : []}
      onSelectionModelChange={handleRowSelect}
      hideFooter
      sx={{
        border: 0,
        m: 3,
        "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
          {
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
      }}
    />
  );
};
