import { Chip, Link, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Date, Duration, DateTime } from "features/datetime";
import moment from "moment";

export type GridColumnOverrides<T extends string = never> = {
  [P in T]?: Partial<GridColDef>;
};

type CreateColumnReturn =
  | Partial<GridColDef>
  | [Partial<GridColDef>, Partial<GridColDef>];

function createColumn<T = never>(
  presets: (definition: GridColDef, options?: T) => CreateColumnReturn
) {
  return (
    definition: GridColDef,
    overrides: GridColumnOverrides<string> = {},
    options?: T
  ): GridColDef => {
    let suggestedValues: Partial<GridColDef>;
    let forcedValues: Partial<GridColDef> | undefined;

    const presetResult = presets(definition, options);
    if (Array.isArray(presetResult)) {
      [suggestedValues, forcedValues] = presetResult;
    } else {
      [suggestedValues, forcedValues] = [presetResult, undefined];
    }

    const fieldName = definition.field;
    const overriddenValues = overrides[fieldName];

    return {
      ...suggestedValues,
      ...definition,
      ...overriddenValues,
      ...forcedValues,
    };
  };
}

export const string = createColumn(() => ({}));

export const number = createColumn<{ precision?: number }>(
  (definition, options = {}) => [
    {
      type: "number",
      align: "center",
      headerAlign: "center",
      valueFormatter: (x) => {
        if (typeof x.value !== "number") {
          return x.value;
        }

        if (options.precision !== undefined) {
          return x.value.toFixed(options.precision);
        }

        return x.value;
      },
    },
    {
      valueGetter: (x) => {
        const value =
          typeof definition.valueGetter === "function"
            ? definition.valueGetter(x)
            : x.value;

        if (typeof value !== "number") {
          return null;
        }

        if (options.precision !== undefined) {
          return +value.toFixed(options.precision);
        }

        return value;
      },
    },
  ]
);

export const boolean = createColumn<{ showFalseValues?: boolean }>(
  (definition, options = {}) => [
    {
      width: 140,
      align: "center",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: ["Yes", "No"],
      renderCell: (cell) => {
        if (!cell.value) {
          return "";
        }

        if (cell.value === "No" && !options.showFalseValues) {
          return "";
        }

        return (
          <Chip
            label={cell.value}
            size="small"
            sx={{
              color: "text.secondary",
            }}
          />
        );
      },
    },
    {
      valueGetter: (x) => {
        const value =
          typeof definition.valueGetter === "function"
            ? definition.valueGetter(x)
            : x.value;

        if (typeof value !== "boolean") {
          return null;
        }

        return value ? "Yes" : "No";
      },
    },
  ]
);

export const percentage = createColumn<{ precision?: number }>(
  (definition, options = {}) => ({
    width: 125,
    align: "center",
    headerAlign: "center",
    headerName: "Percentage",
    type: "number",
    valueFormatter: ({ value }) => {
      if (typeof value !== "number") {
        return value;
      }

      const adjustedValue = value * 100;

      if (options.precision !== undefined) {
        return `${adjustedValue.toFixed(options.precision)}%`;
      }

      return `${adjustedValue}%`;
    },
  })
);

export const dateTime = createColumn((definition) => [
  {
    width: 180,
    type: "dateTime",
    renderCell: (cell) => (
      <Tooltip
        title={
          <DateTime
            seconds={moment(cell.value).unix()}
            local
            short
            precision={"milliseconds"}
          />
        }
        placement="top-start"
      >
        <span>
          <DateTime
            seconds={moment(cell.value).unix()}
            local
            short
            precision={"seconds"}
          />
        </span>
      </Tooltip>
    ),
  },
  {
    valueGetter: (x) => {
      const value =
        typeof definition.valueGetter === "function"
          ? definition.valueGetter(x)
          : x.value;

      if (typeof value !== "number") {
        return null;
      }

      return moment.unix(value).toDate();
    },
  },
]);

export const date = createColumn(() => ({
  width: 125,
  type: "string",
  renderCell: (cell) => <Date seconds={cell.value} short={true} />,
}));

export const id = createColumn(() => ({
  headerName: "ID",
  hide: true,
}));

export const duration = createColumn(() => ({
  width: 150,
  renderCell: (cell) => <Duration seconds={cell.value} />,
}));

export const recorderName = createColumn(() => ({
  width: 225,
  headerName: "Recording Server",
}));

export const hardwareName = createColumn(() => ({
  width: 375,
  headerName: "Hardware",
}));

export const cameraName = createColumn(() => ({
  width: 450,
  headerName: "Camera",
}));

export const address = createColumn<{ clickable?: boolean }>(
  (definition, options = {}) => ({
    width: 200,
    headerName: "Address",
    renderCell: (cell) => {
      if (options.clickable) {
        return (
          <Link href={cell.value as string} target="_blank">
            {cell.value}
          </Link>
        );
      }

      return cell.value;
    },
  })
);
