import { Paper, Stack } from "@mui/material";
import { PageLayout } from "pages/components/PageLayout";
import React, { useMemo, useState } from "react";
import { useRecipients } from "features/recipient";
import { RecipientDetailGrid } from "./RecipientDetailGrid";
import { RecipientHeader } from "./RecipientHeader";

const RecipientPageComponent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Paper
    elevation={4}
    sx={{
      minWidth: 600,
      minHeight: 450,
      flexGrow: 1,
      borderRadius: 3,
      display: "flex",
      overflow: "hidden",
    }}
  >
    {children}
  </Paper>
);

export const Recipient: React.FC = () => {
  const [selectedRecipientId, setSelectedRecipientId] = useState<string>();

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: recipients, isLoading } = useRecipients();

  const filteredRecipients = useMemo(() => {
    if (!recipients) {
      return [];
    }

    let result = [...recipients.recipients];

    const term = searchTerm.trim().toLowerCase();
    if (term.length > 0) {
      result = result.filter(
        (x) =>
          x.name.toLowerCase().includes(term) ||
          x.email.toLowerCase().includes(term)
      );
    }

    return result;
  }, [searchTerm, recipients]);

  return (
    <PageLayout
      title={
        <RecipientHeader
          searchTerm={searchTerm}
          setIsSearching={setIsSearching}
          onSearchChange={setSearchTerm}
        />
      }
      component={RecipientPageComponent}
    >
      <Stack
        direction="row"
        sx={{
          overflow: "hidden",
          flex: "1",
        }}
      >
        <RecipientDetailGrid
          loading={isLoading}
          recipients={filteredRecipients}
          selectedRow={selectedRecipientId}
          onRowSelect={(x) => setSelectedRecipientId(x)}
        />
      </Stack>
    </PageLayout>
  );
};
