import {
  Checkbox,
  FormControlLabelProps,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { FormMultiSelect } from "features/form";
import React from "react";
import { FieldValues, useWatch, Path } from "react-hook-form";
import { FormControlProps } from "features/form";
import { campaignTriggers } from "app/data";

const renderSelectValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.length === 0
      ? "All Triggers"
      : `${value.length} trigger${value.length === 1 ? "" : "s"}`;
  }
};
interface ControlProps {}

export interface CampaignTriggerMultiSelectProps<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
> extends FormControlProps<ControlProps, TFieldValues, TFieldName> {
  label?: React.ReactNode;
  FormControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
}

export function CampaignTriggerMultiSelect<
  TFieldValues extends FieldValues,
  TFieldName extends Path<TFieldValues>
>({
  control,
  name,
}: CampaignTriggerMultiSelectProps<TFieldValues, TFieldName>) {
  const selectedCampaignTriggers = useWatch({ name, control });

  return (
    <FormMultiSelect
      control={control}
      name={name}
      label="Triggers"
      size="small"
      sx={{ width: 358 }}
      renderValue={renderSelectValue}
      displayEmpty
      MenuProps={{ style: { height: 200 } }}
    >
      {campaignTriggers.map((campaignTrigger, index) => (
        <MenuItem key={index} value={index}>
          <Checkbox
            checked={selectedCampaignTriggers.includes(index)}
            sx={{ p: 0, pr: 1 }}
          />
          <ListItemText primary={campaignTrigger} />
        </MenuItem>
      ))}
    </FormMultiSelect>
  );
}
